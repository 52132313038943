import React from 'react';
import { Bar } from 'react-chartjs-2';

const RegionChart = () => {
    const customers = [
        { name: 'أحمد', gender: 'ذكر', age: 25, region: 'القاهرة' },
        { name: 'سارة', gender: 'أنثى', age: 30, region: 'الإسكندرية' },
        { name: 'محمد', gender: 'ذكر', age: 40, region: 'القاهرة' },
        { name: 'ليلى', gender: 'أنثى', age: 35, region: 'الإسكندرية' },
        { name: 'علي', gender: 'ذكر', age: 50, region: 'أسيوط' },
        { name: 'نور', gender: 'أنثى', age: 28, region: 'الإسكندرية' },
        { name: 'يوسف', gender: 'ذكر', age: 22, region: 'القاهرة' },
      ];
      const regionData = customers.reduce((acc, customer) => {
    acc[customer.region] = (acc[customer.region] || 0) + 1;
    return acc;
  }, {});

  // إعداد البيانات للرسم البياني
  const data = {
    labels: Object.keys(regionData),
    datasets: [
      {
        label: 'العملاء حسب المنطقة',
        data: Object.values(regionData),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <div>
        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
        <Bar data={data} />
      </div>
    </div>
  );
};

export default RegionChart;
