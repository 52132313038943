import './DataCustomers.css';
import './DataVendor.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import Header from './Header/Header';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';


function DataVendor() {
  const { t } = useTranslation();


  
const vendor_detail = useSelector(state => state.Data.vendor_details);
const loadingVendor_details = useSelector(state => state.Data.loadingVendor_details);
console.log(vendor_detail)

const listOrders = [
  t('listOrders.serialNumber'),
  t('listOrders.product'),
  t('listOrders.totalQuantity'),
  t('listOrders.remaining'),
  t('listOrders.sold')
];
  return (
    <div className="DataCustomers">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i><FontAwesomeIcon icon={faCartPlus} /></i>
            <p>Orders Management</p>
          </div>
        </div>

        <div className='container'>
          <div className='row align-items-center'>
            <div className='Pending align-items-center'>

                <div className='box1 col-4 g-0 p-2'>
                  <div className='p-2'>
                    <img src={vendor_detail?.data?.data?.user_image } alt='vendor'/>
                    <p>{vendor_detail?.data?.data?.name}</p>
                  </div>

                </div>
                <div className='box3 col-8 g-0'>
                  <div className='cont2 p-2'>
                  <div>
                    <span>{vendor_detail?.data?.data?.total_products}</span>
                    <p>{t('totalProducts')}</p>
                  </div>
                  <div>
                    <span>{vendor_detail?.data?.data?.total_sales}</span>
                    <p>{t('totalSales')}</p>
                  </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            {loadingVendor_details && <Loader />}
            <div className='Recent'>Inventory</div>
            <div className='Orders_border row'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {vendor_detail?.data?.data?.products.map((order) => (
              <div key={order.id}>
                <div
                  className='Orders_border row'
                >
                  <p className='col-2 text-center'>{order.id}</p>
                  <p className='col-2 text-center'>{order.name}</p>
                  <p className='col-2 text-center'>{order.total_quantity}</p>
                  <p className='col-2 text-center'>{order.remaining_quantity}</p>
                  <p className='col-2 text-center'>{order.sold_quantity}</p>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataVendor;