import React, { useState, useCallback } from "react";
import './AddAdveritsement.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faChevronRight, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { post_ads, edit_ad, category, all_vendors } from "./Redux/API";
import { useEffect } from "react";
import Loader from './Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import img from './images/88209500.jpg'

function AddAdveritsement() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ad = useSelector(state => state?.products?.ads || null);
    const loadingPost_ads = useSelector(state => state.Data.loadingPost_ads);
    const loadingEdit_ad = useSelector(state => state.Data.loadingEdit_ad);
    const Edit_ad = useSelector(state => state.Data.edit_ad);
    const Post_ad = useSelector(state => state.Data.post_ads);

    // حالة اسم المهمة
    const [taskName, setTaskName] = useState(ad?.title || ""); 
    // حالة الصورة الواحدة
    const [singleImage, setSingleImage] = useState(ad?.image_url ? [{ preview: ad.image_url }] : []); 
    const [showPopup, setShowPopup] = useState(false); // حالة ظهور الـ popup

    // وظيفة معالجة الصور عند سحبها
    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            setSingleImage([{ preview: URL.createObjectURL(file), file }]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    // إعادة تعيين المدخلات
    const resetInputs = () => {
        setTaskName("");
        setSingleImage([]);
    };

    // التحقق من المدخلات
    const validateInputs = () => {
        return taskName.trim() !== "" && (singleImage.length > 0 && (singleImage[0].file || singleImage[0].preview));
    };

    const [showPopup_massge, setshowPopup_massge] = useState(false);

    const uploadTask = (e) => {
        e.preventDefault();

        if (!validateInputs()) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

        const adData = {
            name: taskName,
            description: 'وصف الإعلان', 
            category_id,
            vendor_id, 
            singleImage: singleImage[0].file ? singleImage[0].file : null, 
            link: undefined,
        };

        if (ad) {
            dispatch(edit_ad({ ...adData, id: ad.id })).then((action) => {
                if (action.payload && action.payload.success) {
                    resetInputs();
                    setshowPopup_massge(true); 
                    setTimeout(() => setshowPopup_massge(false), 4000); 

                }
            });
        } else {
            dispatch(post_ads(adData)).then((action) => {
                if (action.payload && action.payload.success) {
                    resetInputs();
                    setshowPopup_massge(true); 
                    setTimeout(() => setshowPopup_massge(false), 4000); 

                }
            });
        }

    };

    const goBack = () => {
        navigate(-1);
    };


        useEffect(() => {
          dispatch(category())
        }, [dispatch])
    
        const categorys = useSelector(state => state.Data.category);
    
    const[icon5, setIcon5] = useState(false)
      const [typeCategory, setTypeCategory] = useState(ad?.category_name || null);
      const [category_id, setCategory_id] = useState(ad?.category_id || null);


      const [vendor_name, setVendor_name] = useState(ad?.type_info.name || null);
      const [vendor_id, setVendor_id] = useState(ad?.type_info.id || null);

        useEffect(() => {
          dispatch(all_vendors());
        }, [dispatch]);
      const all_vendor = useSelector(state => state.Data.all_vendors);

         const[icon6, setIcon6] = useState(false)
          function handelClick6() {
            setIcon6(!icon6)
          }
      
console.log(categorys)
    return (
        <div className="AddAdveritsement">
            <Header />
            <div className="container">
                {loadingPost_ads && <Loader />}
                {loadingEdit_ad && <Loader />}
                <div className='row align-items-center'>
                    <div className="container icon">
                        <i className="i" onClick={goBack}><FontAwesomeIcon icon={faArrowLeft} /></i>
                    </div>
                </div>

                <form className="container row justify-content-around" onSubmit={uploadTask}>
                    <div className="row justify-content-between mt-5">
                        <div className="n col-5 mt-4">
                            <div className="divll col-12 mb-4">
                                <label htmlFor='name'>{t('name')}</label>
                                <input
                                    type="text"
                                    id="Name"
                                    onChange={(e) => setTaskName(e.target.value)}
                                    value={taskName}
                                    required
                                />
                            </div>


                            {/* <p className="m-0">Select category</p>

                            <div className="box_AddCompany div4 w-100">
                                <div className="header" onClick={() => setIcon5(!icon5)}>
                                    <span>{typeCategory}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                                </div>

                                {icon5 && (
                                    <div className="cat category_container">
                                        <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "200px", overflow: "hidden" }}>
                                            {categorys?.data?.data.map((category) => (
                                                <div key={category.id} className="category-item">
                                                    <div className="category_content"
                                                    onClick={() => {
                                                        setTypeCategory(category.name);
                                                        setCategory_id(category.id);
                                                        setIcon5(false);
                                                        setVendor_name(null);
                                                        setVendor_id(null);
                                                    }}
                                                    >
                                                    <img 
                                                        src={category.image || '/default-category.png'} 
                                                        alt={category.name} 
                                                        className="category-image"
                                                    />

                                                    <p className="border-0">
                                                        {category.name}
                                                    </p>
                                                    </div>
                                                    {category.subcategories && category.subcategories.length > 0 && (
                                                        <div className="subcategory-list">
                                                            {category.subcategories.map((subCategory) => (
                                                                <>

                                                            <div className="subcategory_content" 
                                                            onClick={() => {
                                                                setTypeCategory(subCategory.name);
                                                                setCategory_id(subCategory.id);
                                                                setIcon5(false);
                                                                setVendor_name(null);
                                                                setVendor_id(null);
                                                            }}
                                                            >
                                                            <img 
                                                                src={subCategory.image || '/default-category.png'} 
                                                                alt={subCategory.name} 
                                                                className="category-image"
                                                            />

                                                            <p  className="border-0"
                                                                style={{ fontWeight: "bold", cursor: "pointer" }}
                                                            >
                                                                {subCategory.name}
                                                            </p>
                                                            </div>
                                                                </>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </PerfectScrollbar>
                                    </div>
                                )}
                            </div> */}



                        <div className="div mt-4">
                        <p className="m-0">Select vendor</p>

                            <div className="box_AddCompany div4 w-100" onClick={handelClick6}>
                                <div className="heade">
                                    <span>{vendor_name}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight}/></i>
                                </div>
                                {icon6 &&
                                    <div className="cat category_container">
                                        <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "200px", overflow: "hidden" }}>
                                            {all_vendor?.data?.data?.users.map((vendor, i) => 
                                                <div
                                                className="slect_vendor"
                                                onClick={() => {
                                                  setVendor_name(vendor.name);
                                                  setVendor_id(vendor.id);
                                                  setIcon6(!icon6);
                                                  setTypeCategory(null);
                                                  setCategory_id(null);
                                                }}
                                              >
                                                    <img src={vendor.user_image} className="category-image" alt="img"/>
                                                    <p className="border-0">{vendor.name}</p>
                                                </div>
                                            )}
                                        </PerfectScrollbar>
                                    </div>
                                }
                            </div>
                        </div>


                        </div>



                        <div className="col-7">
                            <div className="Add_img ">
                                <span>{t('addCategory.singlePhoto')}</span>
                                <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {singleImage.length === 0 ? (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addCategory.dragAndDropSingle')}</p>
                                        </div>
                                    ) : (
                                        <img
                                            src={singleImage[0].preview}
                                            alt="Preview"
                                            className="img"
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <button className="button2 col-5 mt-5 p-0 " type="submit">
                                {ad ? t('edit') : t('create')}
                            </button>
                    </div>
                    
                </form>
                {showPopup_massge && (
                <div className="popup-message">
                    <div className="popup-conten">
                    {(Post_ad?.data?.message || Edit_ad?.data?.message) &&
                    <FontAwesomeIcon icon={faCheck} className='mx-1' />
                    }
                    {Post_ad?.data?.message && 
                        <span>{Post_ad?.data?.message}</span>
                    }
                    {Edit_ad?.data?.message && 
                        <span>{Edit_ad?.data?.message}</span>
                    }
                    </div>
                </div>
            )}

            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>
        </div>
    );
}

export default AddAdveritsement;
