import React, { useState, useCallback, useEffect } from "react";
import './AddCategoire.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faCheck, faChevronRight, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { post_category, category } from "./Redux/API";
import Loader from './Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

function AddCategoire() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadingShow_category = useSelector(state => state.Data.loadingShow_category);

  useEffect(() => {
    dispatch(category())

    }, [dispatch]);

    const categoryList = useSelector(state => state.Data.category);
    const loadingPost_category = useSelector(state => state.Data.loadingPost_category);
    const post_categorys = useSelector(state => state.Data.post_category);
    const errorPost_category = useSelector(state => state.Data.errorPost_category);
    const loadingEdit_product = useSelector(state => state.Data.loadingEdit_product);
    const [taskNameAr, setTaskNameAr] = useState('');
    const [taskNameEn, setTaskNameEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [nameCategory, setNameCategory] = useState('');
    
    const [showBox, setShowBox] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSingleImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    const handleToggle = (id, name) => {
        setParentId(id);
        setActiveCategory(id);
        setNameCategory(name)
        setShowBox(false);
    };
    
const handleBack = () => {

        setTaskNameAr('');
        setTaskNameEn('');
        setDescriptionAr('');
        setDescriptionEn('');
        setSingleImage(null);
        setParentId(null);
        setActiveCategory(null);
        navigate(-1);
};

useEffect(() => {
    setTaskNameAr('');
    setTaskNameEn('');
    setDescriptionAr('');
    setDescriptionEn('');
    setSingleImage(null);
    setParentId(null);
    setActiveCategory(null);
  }, [loadingEdit_product])

   


    const uploadTask = (e) => {
        e.preventDefault();
    
        if (!taskNameAr || !taskNameEn || !descriptionEn || !descriptionAr) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }

            dispatch(post_category({
                taskNameAr,
                taskNameEn,
                descriptionAr,
                descriptionEn,
                singleImage,
                parentId,
            })).then((action) => {
                if (action.payload && action.payload.success) {
                    setSuccess(true); // تحديث الحالة عند نجاح العملية
                }
              });

    };
    const [success, setSuccess] = useState(false);
    const [showPopup_massge, setshowPopup_massge] = useState(false);
    useEffect(() => {
        if (success) {
          setTaskNameAr("");
          setTaskNameEn("");
          setDescriptionAr("");
          setDescriptionEn("");
          setSingleImage(null);
          setParentId(null);
          setshowPopup_massge(true); 
          setTimeout(() => setshowPopup_massge(false), 4000); 
          setSuccess(false);
        }
      }, [success]);

    return (
        <div className="AddCategoire">
            <Header />
            <div className="container">
                {loadingPost_category && <Loader />}
                {loadingShow_category && <Loader />}
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i><FontAwesomeIcon icon={faCartPlus} /></i>
                        <p>{t('addCategory.title')}</p>
                    </div>
                    <div className="container icon">
                    <i 
                        className="i" 
                        onClick={handleBack}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </i>
                    </div>
                </div>
                <form className="container row justify-content-around" onSubmit={uploadTask}>
                    <div className="col-6">
                        <div className="Add_img">
                            <p className="mb-1">{t('addCategory.singlePhoto')}</p>
                            <div className="add" {...getRootPropsSingle()}>
                                <input {...getInputPropsSingle()} />
                                {!singleImage && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addCategory.dragAndDropSingle')}</p>
                                    </div>
                                )}
                                {singleImage && (
                                    <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
                                )}
                            </div>
                        </div>
                        <div className="div">
                            <p className=" mb-1 mt-4">{t('addProduct.category')}</p>
                            <div onClick={() => setShowBox(!showBox)} className="slect_category">
                            <p>{nameCategory || t('select_category')}</p>
                            <i><FontAwesomeIcon icon={faChevronRight} /></i>
                            </div>
                            {showBox && (
                                <div>
                                    <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "150px", overflow: "hidden" }}>

                                    {categoryList?.data?.data.map((category) => (

                                        <div key={category.id} className="boxH">
                                            <div onClick={() => handleToggle(category.id, category.name)} className="oox_container">

                                                <div className="box_header">
                                                    <span>{category.name}</span>
                                                    {activeCategory === category.id && <span>✅</span>}
                                                </div>

                                            </div>
                                        </div>

                                    ))}
                                        </PerfectScrollbar>

                                </div>
                            )}
                        </div>

                    </div>

                    <div className="project_data col-5">
                    <div className="div1">
                        <label htmlFor="taskNameAr">{t('name_ar')}</label>
                        <input
                        type="text"
                        id="taskNameAr"
                        onChange={(e) => setTaskNameAr(e.target.value)}
                        value={taskNameAr}
                        />
                    </div>
                    <div className="div1">
                        <label htmlFor="taskNameEn">{t('name_en')}</label>
                        <input
                        type="text"
                        id="taskNameEn"
                        onChange={(e) => setTaskNameEn(e.target.value)}
                        value={taskNameEn}
                        />
                    </div>
                    <div className="div2">
                        <label htmlFor="descriptionAr">{t('description_ar')}</label>
                        <input
                        type="text"
                        id="descriptionAr"
                        onChange={(e) => setDescriptionAr(e.target.value)}
                        value={descriptionAr}
                        />
                    </div>
                    <div className="div2">
                        <label htmlFor="descriptionEn">{t('description_en')}</label>
                        <input
                        type="text"
                        id="descriptionEn"
                        onChange={(e) => setDescriptionEn(e.target.value)}
                        value={descriptionEn}
                        />
                    </div>
                    </div>
                    <button type="submit" className="m-4 col-6">{t('create_button')}</button>
                </form>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>
            {showPopup_massge && (
                <div className="popup-message">
                    <div className="popup-conten">
                    {post_categorys?.data?.message&&
                    <FontAwesomeIcon icon={faCheck} className='mx-1' />
                    }
                    <span>{post_categorys?.data?.message}</span>
                    <span>{errorPost_category?.message}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddCategoire;