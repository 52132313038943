import './ProductsList.css';
import './InventoryReports.css';
import './ProductReports.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { searsh_product, vendor_sales_report } from "./Redux/API";
import Loader from './Loader';
import { useTranslation } from 'react-i18next';

function ProductReports() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searsh_products = useSelector(state => state.Data.searsh_product);
  const loadingSearsh_products = useSelector(state => state.Data.loadingSearsh_products);
  const vendor_sales_reports = useSelector(state => state.Data.vendor_sales_report);
  const loadingVendor_sales_reports = useSelector(state => state.Data.loadingLow_stockS);

  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => setSearshBroduct(e.target.value);

  useEffect(() => {
    dispatch(searsh_product(searshBroduct));
  }, [searshBroduct, dispatch]);

  useEffect(() => {
    dispatch(vendor_sales_report());
  }, [dispatch]);

  const listOrders = [
    t('products_list.image'), 
    t('products_list.name'), 
    t('products_list.category'), 
    t('products_list.price')
  ];

  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        {loadingVendor_sales_reports && <Loader />}
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh col-5'>
              {/* <input
                type="text"
                value={searshBroduct}
                onChange={handleInputChangeReturns}
                placeholder={t('products_list.search')}
              /> */}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='title_color mt-2'>
              <h5 className='mb-3'>{t('products_list.top_selling')}</h5>
              <div className='Orders_border row justify-content-between'>
                {listOrders.map((e, index) => (
                  <p className='col-3 text-center mb-3' key={index}>{e}</p>
                ))}
              </div>
            </div>

            {vendor_sales_reports?.data?.data?.top_selling_products.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center justify-content-between'>
                  <p className='col-3 text-center'>
                    <img src={item?.product?.main_image} alt={item.id}/>
                  </p>
                  <p className='col-3 text-center'>{item?.product?.name}</p>
                  <p className='col-3 text-center'>{item?.product?.category}</p>
                  <p className='col-3 text-center'>{item?.product?.new_price}</p>
                </div>
              </div>
            ))}

            <div className='title_color mt-2'>
              <h5 className='mb-3'>{t('products_list.least_selling')}</h5>
              <div className='Orders_border row justify-content-between'>
                {listOrders.map((e, index) => (
                  <p className='col-3 text-center mb-3' key={index}>{e}</p>
                ))}
              </div>
            </div>

            {vendor_sales_reports?.data?.data?.least_selling_products.map((item) => (
              <div key={item.id}>
                <div className='Orders_border row align-items-center justify-content-between'>
                  <p className='col-3 text-center'>
                    <img src={item?.product?.main_image} alt={item.id}/>
                  </p>
                  <p className='col-3 text-center'>{item?.product?.name}</p>
                  <p className='col-3 text-center'>{item?.product?.category}</p>
                  <p className='col-3 text-center'>{item?.product?.new_price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductReports;
