
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faMagnifyingGlass, faBell} from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import i18n from "../i18n";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { notifications, vendor_profie } from '../Redux/API';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import logo from '../images/Group.png';


function Header(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const gotoProfile = () => {
        navigate('/Profile');
    };



    const languages = ['AR', 'EN'];

    const [language, setLanguage] = useState(sessionStorage.getItem('languages') || 'en');

    const [text, setText] = useState(language === 'ar' ? 'AR' : 'EN');
    const [icon, setIcon] = useState(false);

    // تحديث i18n والاتجاه عند تغيير اللغة
    useEffect(() => {
      // تغيير لغة الترجمة
      i18n.changeLanguage(language);
      // تحديث اتجاه النص واتجاه الصفحة
      const body = document.body;
      const html = document.documentElement;
  
      if (language === 'ar') {
        body.style.textAlign = 'right';
        html.setAttribute("dir", "rtl");
        setText('AR');
      } else {
        body.style.textAlign = 'left';
        html.setAttribute("dir", "ltr");

        setText('EN');
      }
  
      // تخزين اللغة في sessionStorage
      sessionStorage.setItem('languages', language);
      sessionStorage.setItem('text_language', language === 'ar' ? 'AR' : 'EN');
    }, [language]);
  
    // تبديل حالة الأيقونة
    const handelClick = () => {
      setIcon(!icon);
    };
  
    // تغيير اللغة
    const handleLanguageChange = (lang) => {
      setLanguage(lang); // يتم تحديث اللغة وستقوم useEffect بمعالجة الباقي
    };
  


  
    const getLocalStorage = window.sessionStorage.getItem('VendorInfo');
    const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
    const token = valueLocalStorage?.data?.token || null;

    const notification = useSelector(state => state.Data.notifications);
    useEffect(() => {
        dispatch(notifications())
    }, [dispatch])
    const [showNotifications, setShowNotifications] = useState(false);

    const toggleNotifications = () => {
        setShowNotifications(!showNotifications);
    };
    const [showadmin, setShowadmin] = useState(valueLocalStorage?.data?.data?.user?.role)
    const update_profile_user = useSelector(state => state.Data.update_profile);
    const vendor_profie_data = useSelector(state => state.Data.vendor_profie);
  
    useEffect(() => {
      dispatch(vendor_profie());
    }, [dispatch, update_profile_user]);



    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
        return new Date(dateString).toLocaleDateString('ar-EG', options);
      }

    return (
        <div className="Header">
            <div className={`naveBar `}>
                <div className='logo_header'>
                <img src={logo} alt='logo'/>
                </div>
                <div className={`inpute_search`}>
                    <i className='magnifier '><FontAwesomeIcon icon={faMagnifyingGlass} /></i> 
                    <input type="text" name="search" readOnly/>
                </div>

                <div className='group_profile'>
                    <div className='img_name'>
                {showadmin !== 'admin' &&

                        <div>
                            <img src={vendor_profie_data?.data.data?.user_image} alt='User' onClick={gotoProfile}/>
                            <span>{vendor_profie_data?.data?.data?.vendor_info.company}</span>
                        </div>
                }

                    </div>
                    <div className='ntofcation'onClick={toggleNotifications}>
                        <i ><FontAwesomeIcon icon={faBell} /></i>
                        {notification?.data?.data?.length > 0 && <span className='notification-dot'>{notification?.data?.data?.length}</span>}
                        
                    </div>
                    <div className={`notifications-dropdown ${showNotifications ? 'show' : ''}`}>

                    <ul>
                    {notification?.data?.data?.length > 0 ? (
                        <PerfectScrollbar style={{ width: "100%", maxHeight: "300px", overflow: "hidden" }}>
                        <div className="notifications-container">
                            {notification?.data?.data.map((notif, index) => (
                            <li key={index} className="notification-item">
                                <div className="notification-content">
                                <span className="notification-text">{notif?.data}</span>
                                <span className="notification-date">{formatDate(notif.created_at)}</span>
                                </div>
                            </li>
                            ))}
                        </div>
                        </PerfectScrollbar>
                    ) : (
                        <li className="no-notifications">لا توجد إشعارات</li>
                    )}
                    </ul>


                    </div>
                    <div className="language ">
                        <div className="slectList">
                            <p>{text}</p>
                            <i onClick={handelClick} className={`${icon ? 'activ_p' : 'noActiv_p'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                        </div>

                        {icon &&
                            <div className="lest">
                                {languages.map((e, i) => 
                                    <p key={i} onClick={() => (setText(e), setIcon(!icon), handleLanguageChange(e.toLowerCase()))}>{e}</p>
                                )}
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Header;