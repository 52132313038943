import './Adveritsement.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd, faCartPlus, faCheck, faEdit, faPlus, faPlusCircle, faShoppingCart, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ads, delete_ad } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
import { EditAd } from "./Redux/HomeSlice";


function Adveritsement() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gotoedit_ad = (item) => {
    dispatch(EditAd(item));
    navigate('/AddAdveritsement');
  };

  const ad = useSelector(state => state.Data.ads);
  const loadingAds = useSelector(state => state.Data.loadingAds);
  const loadingDelete_ad = useSelector(state => state.Data.loadingDelete_ad);
  useEffect(() => {
    dispatch(ads())

  }, [dispatch, loadingDelete_ad])

  const gotoAddAdveritsement = () => {
    navigate('/AddAdveritsement');
  };

  

  const handleDeleteAd = (id) => {
    dispatch(delete_ad(id));
  };

  const listOrders = ['IMAGE', 'NAME', 'DATA AND TIME', 'ACTION'];


  const [showPopupMessage, setshowPopupMessage] = useState(false);

  useEffect(() => {
      if (loadingDelete_ad) {
          setshowPopupMessage(true);
        const timer = setTimeout(() => {
          setshowPopupMessage(false);
        }, 3000);
    
        return () => clearTimeout(timer); // تنظيف الـ timer عند التغيير
      }
    }, [loadingDelete_ad]);
  return (
    <div className="Adveritsement">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faAd} /></i>
            <p>{t('advertisement.title')}</p> {/* ترجمة العنوان */}
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <div className='AddCat' onClick={gotoAddAdveritsement}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('advertisement.add_ad')}</span> 
              </div>
            </div>
          </div>
        </div>
        {showPopupMessage && (
            <div className="popup-message">
            <div className="popup-conten">
            </div>
        </div>
        )}
        <div className='container'>
          <div className='Orders'>
            {loadingAds && <Loader/>}
            {loadingDelete_ad && <Loader/>}

            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-3 text-center' key={index}>{t(`advertisement.columns.${index}`)} </p> 
              ))}
            </div>

            {ad?.data?.data.map((order) => (
              <div key={order.id}>
                {loadingAds && <Loader />}
                <div className='Orders_border row justify-content-around'>
                  <p className='col-3 text-center'><img src={order.image_url} alt={order.id} /></p>
                  <p className='col-3 text-center'>{order.title}</p>
                  <p className='col-3 text-center'>{order?.type_info?.name}</p>
                  <p className='col-3 text-center'>
                    <span className='span_icon' onClick={() => gotoedit_ad(order)}><FontAwesomeIcon icon={faEdit} /></span>
                    <span 
                      className='span_icon2' 
                      onClick={() => handleDeleteAd(order.id)}  // تمرير الـ id للباك إند
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Adveritsement;
