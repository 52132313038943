import React from 'react';
import { Pie } from 'react-chartjs-2';

const GenderChart = () => {
    const customers = [
        { name: 'أحمد', gender: 'ذكر', age: 25, region: 'القاهرة' },
        { name: 'سارة', gender: 'أنثى', age: 30, region: 'الإسكندرية' },
        { name: 'محمد', gender: 'ذكر', age: 40, region: 'القاهرة' },
        { name: 'ليلى', gender: 'أنثى', age: 35, region: 'الإسكندرية' },
        { name: 'علي', gender: 'ذكر', age: 50, region: 'أسيوط' },
        { name: 'نور', gender: 'أنثى', age: 28, region: 'الإسكندرية' },
        { name: 'يوسف', gender: 'ذكر', age: 22, region: 'القاهرة' },
      ];
    
  // تصفية البيانات حسب النوع
  const genderData = customers.reduce(
    (acc, customer) => {
      acc[customer.gender] += 1;
      return acc;
    },
    { ذكر: 0, أنثى: 0 }
  );

  // إعداد البيانات للرسم البياني
  const data = {
    labels: ['ذكر', 'أنثى'],
    datasets: [
      {
        label: 'العملاء حسب النوع',
        data: [genderData.ذكر, genderData.أنثى],
        backgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  return (
    <div >
      <Pie data={data} />
    </div>
  );
};

export default GenderChart;