import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useDispatch, useSelector } from 'react-redux';
import { low_stock, inventory_report, inventory_overview } from './Redux/API';
import Loader from './Loader';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

function PieChart() {
  const dispatch = useDispatch();
  
  const inventory_overviews = useSelector((state) => state.Data.inventory_overview);
  const loadingInventory_overview = useSelector((state) => state.Data.loadingInventory_overview);

  const [chartData, setChartData] = useState({
    labels: ['Sold', 'Remaining'], // Default labels
    datasets: [
      {
        label: 'Products Distribution',
        data: [0, 0], // Default data
        backgroundColor: ['#FF4C4C', '#FFB22C'],
        borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 159, 64, 1)'],
        borderWidth: 1,
      },
    ],
  });

  // Fetch data on mount
  useEffect(() => {
    dispatch(low_stock());
    dispatch(inventory_report());
    dispatch(inventory_overview());
  }, [dispatch]);

  useEffect(() => {
    if (!loadingInventory_overview && inventory_overviews?.data?.data) {
      const { total_products = 0, sold_products = 0, remaining_products = 0 } =
        inventory_overviews.data.data;

      setChartData({
        labels: ['Sold', 'Remaining'],
        datasets: [
          {
            label: 'Products Distribution',
            data: [sold_products, remaining_products],
            backgroundColor: ['#FF4C4C', '#FFB22C'],
            borderColor: ['rgba(75, 192, 192, 1)', 'rgba(255, 159, 64, 1)'],
            borderWidth: 1,
          },
        ],
      });
    }
  }, [loadingInventory_overview, inventory_overviews]);

  const options = {
    plugins: {
      datalabels: {
        color: '#000',
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value, context) => {
          const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
          return `${((value / total) * 100).toFixed(2)}%`;
        },
        anchor: 'center',
        align: 'center',
        offset: 0,
      },
    },
  };

  return (
    <div>
      {loadingInventory_overview ? (
        <Loader />
      ) : (
        <Pie data={chartData} options={options} />
      )}
    </div>
  );
}

export default PieChart;
