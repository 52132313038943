import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { visitors_stats } from "./Redux/API";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

Chart.register(CategoryScale, LinearScale, BarElement);

function SalesChart() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // بيانات من Redux
  const visitors_stat = useSelector(state => state.Data.visitors_stats); // بيانات الـ API
  const loadingVisitors_stats = useSelector(state => state.Data.loadingVisitors_stats);
  const errorVisitors_stat = useSelector(state => state.Data.errorVisitors_stat);

  const [timePeriod, setTimePeriod] = useState('month'); // الفترة الزمنية
  const [startDate, setStartDate] = useState(''); // التاريخ المختار
  console.log(visitors_stat?.data.data.visitors )
  useEffect(() => {
    if (!startDate) {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0]; // تنسيق YYYY-MM-DD
      setStartDate(formattedDate);
    }
  }, [startDate]);

  useEffect(() => {
    if (startDate) {
      dispatch(visitors_stats({ start_date: startDate }));
    }
  }, [startDate, dispatch]);

  const handleDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleFetchData = () => {
    if (startDate) {
      dispatch(visitors_stats({ start_date: startDate }));
    } else {
      alert(t('salesChart.startDate'));
    }
  };

  // التسميات بناءً على الفترة الزمنية
  const labelsForPeriod = {
    week: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    month: Array.from({ length: 31 }, (_, i) => `Day ${i + 1}`),
    year: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
  };

  // بيانات الرسم البياني من الـ API
  const dataForPeriod = {
    week: visitors_stat?.data.data.visitors?.week || Array(7).fill(0), // بيانات الأسبوع
    month: visitors_stat?.data.data.visitors?.month || Array(31).fill(0), // بيانات الشهر
    year: visitors_stat?.data.data.visitors?.year || Array(12).fill(0), // بيانات السنة
  };

  // تكوين بيانات الرسم البياني
  const data = {
    labels: labelsForPeriod[timePeriod],
    datasets: [
      {
        label: t('salesChart.customers'),
        data: dataForPeriod[timePeriod],
        backgroundColor: 'rgba(75,192,192,0.6)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  // خيارات الرسم البياني
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="container mt-4">
      {/* إدخال التاريخ */}
      <div className="mb-4">
        <input
          type="date"
          id="startDate"
          className="form-control"
          value={startDate}
          onChange={handleDateChange}
        />

        <div className='d-flex justify-content-between col-12 mt-3'>
          <div className='col-3'>
            <button className="btn btn-primary mt-2" onClick={handleFetchData}>
              {t('salesChart.fetchData')}
            </button>
          </div>

          <div className="btn-group" role="group" aria-label="Time period selection">
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'week' ? 'active' : ''}`}
              onClick={() => setTimePeriod('week')}
            >
              {t('salesChart.week')}
            </button>
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'month' ? 'active' : ''}`}
              onClick={() => setTimePeriod('month')}
            >
              {t('salesChart.month')}
            </button>
            <button
              type="button"
              className={`btn btn-primary ${timePeriod === 'year' ? 'active' : ''}`}
              onClick={() => setTimePeriod('year')}
            >
              {t('salesChart.year')}
            </button>
          </div>
        </div>
      </div>

      {/* الرسم البياني */}
      <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>

      {loadingVisitors_stats ? (
        <p>{t('salesChart.loading')}</p>
      ) : errorVisitors_stat ? (
        <p className="text-danger">{t('salesChart.error')}</p>
      ) : (
        <Bar data={data} options={options} />
      )}
      </div>
    </div>
  );
}

export default SalesChart;

