import './Register.css';
import group from "../images/Group.png";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, login, verification, loginFirebase, register_vendor } from '../Redux/API';
import { sendReset, resetPassword, verifyReset } from '../Redux/API';
import { faCartPlus, faCheck, faChevronRight, faCloudUpload, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import image2 from '../images/4957136.jpg'
import Loader from '../Loader';


function Register() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const errorRegister = useSelector(state => state.Data.errorAddUser);
    const messageOTP = useSelector(state => state.Data.errorVerification);
    const errorLogin = useSelector(state => state.Data.errorLogin);

    const userData = useSelector(state => state.Data.userData);

    const loadingAddUser = useSelector(state => state.Data.loadingAddUser);
    const loadingVerification = useSelector(state => state.Data.loadingVerification);
    const loadingLogin = useSelector(state => state.Data.loadingLogin);

    const register_vendors = useSelector(state => state.Data.register_vendor);
    const loadingRegister_vendor = useSelector(state => state.Data.loadingRegister_vendor);

    // const [getLocalStorage, setGetLocalStorage] = useState(() => {
    //     const savedData = window.localStorage.getItem('UserInfo');
    //     return savedData ? JSON.parse(savedData) : null;
    // });

  const [signupEmail, setSignupEmail] = useState(null); 
  const [signupPassword, setSignupPassword] = useState(''); 
  const [companyName, setCompanyName] = useState(''); 
  const [number, setNumber] = useState(''); 
  const [businessType, setBusinessType] = useState(''); 
  const [productsNumber, setProductsNumber] = useState(null); 
  const [address, setAddress] = useState(''); 
  const [typeStore, setTypeStore] = useState(''); 
  const [singleImage, setSingleImage] = useState([]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email);
    console.log(`Email validation for "${email}":`, isValid);
    return isValid;
};
  

    // const [email, setEmail] = useState( getLocalStorage && getLocalStorage?.data && getLocalStorage?.data?.data?.user ? getLocalStorage?.data?.data?.user?.email : signupEmail);

    useEffect(() => {
        if (register_vendors) {
            setShowToken(true);
        }
    }, [register_vendors]);


    const [activeButton, setActiveButton] = useState('login');

  const [errorSingleImage, setErrorSingleImage] = useState(''); // الخطأ إذا حدث مشكلة في الصورة

    const [signupError, setSignupError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('customer');

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [showToken, setShowToken] = useState(false);

    const [showOTP, setShowOTP] = useState(false);
    const isValidPasswordsignup = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };
    
    // const isValidPassword = (password) => {
    //     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    //     return passwordRegex.test(password);
    // };

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    
        // إعادة تعيين الحقول غير النشطة عند التبديل بين الزرين
        if (buttonName === 'signup') {
            setLoginEmail('');
            setLoginPassword('');
            setLoginError('');
        } else if (buttonName === 'login') {
            setSignupEmail('');
            setSignupPassword('');
            setSignupError('');
        }
    };

    const [errors, setErrors] = useState({
        signupEmail: false,
        signupPassword: false,
        companyName: false,
        number: false,
        businessType: false,
        productsNumber: false,
        address: false,
        typeStore: false, 
        singleImage: null, 
    });
    const [errors_login, setErrors_login] = useState({
        loginEmail: false,
        loginPassword: false,
    });
      
      

    useEffect(() => {
        if (activeButton === 'signup') {
            const isValid = signupEmail && signupPassword && companyName && number &&  businessType && productsNumber && address && typeStore && singleImage && isValidEmail(signupEmail) && isValidPasswordsignup(signupPassword);
            setIsFormValid(isValid);
        } else if (activeButton === 'login') {
            const isValid = loginEmail && loginPassword && isValidEmail(loginEmail);
            setIsFormValid(isValid);
        }
    }, [signupEmail, signupPassword, loginEmail, loginPassword, activeButton, companyName, number, businessType, productsNumber, address, typeStore, singleImage]);

    

    const [errors_loin, setErrors_loin] = useState({
        loginEmail: false,
        loginPassword: false,
      });
      
      const handleSubmit = (e) => {
        e.preventDefault();
      
        if (activeButton === 'signup') {
          const newErrors = {
            signupEmail: !isValidEmail(signupEmail),
            signupPassword: !isValidPasswordsignup(signupPassword),
            companyName: !companyName,
            number: !number,
            businessType: !businessType,
            productsNumber: !productsNumber,
            address: !address,
            typeStore: !typeStore,
            singleImage: singleImage.length === 0,
          };
      
          setErrors(newErrors);
      
          const hasErrors = Object.values(newErrors).some((error) => error);
          if (hasErrors) {
            setSignupError(t('Fill Fields With Valid Info'));
            return;
          }
      
          setSignupError('');
          setLoginError('');
      
          dispatch(
            register_vendor({
              email: signupEmail,
              password: signupPassword,
              company_name: companyName,
              phone: number,
              business_type: businessType,
              products_number: productsNumber,
              address: address,
              type_store: typeStore,
              image: singleImage.length > 0 ? singleImage[0] : null,
              role: 'supplier',
            })
          )
            .unwrap()
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                setShowToken(true);
                // const savedData = window.localStorage.getItem('UserInfo');
                // setEmail(savedData ? JSON.parse(savedData).data?.data?.user?.email : null);
              }
            })
            .catch((error) => {
              console.error('Error during registration:', error);
              setSignupError(error.message || t('generalError'));
            });
        } else if (activeButton === 'login') {
          const newError = {
            loginEmail: !isValidEmail(loginEmail),
            loginPassword: !loginPassword,
          };
      
          setErrors_login(newError);
      
          const hasErrors = Object.values(newError).some((error) => error);
          if (hasErrors) {
            setLoginError(t('Fill Fields With Valid Info'));
            return;
          }
      
          setSignupError('');
          setLoginError('');
      
          dispatch(
            login({
              email: loginEmail,
              password: loginPassword,
            })
          )
            .unwrap()
            .then((response) => {
              if (response.success === true) {
                window.location.reload();
              }
            })
            .catch((error) => {
              setLoginError(error.message || t('General Error'));
            });
        }
      };
      

    const verificationData = useSelector(state => state.Data.verificationData);
    const logins = useSelector(state => state.Data.login);
console.log(logins)
    // هنستخدم useEffect عشان نحفظ البيانات في localStorage
    useEffect(() => {
        if (verificationData || logins) {
            const VendorInfo = verificationData ? verificationData : logins;
            window.sessionStorage.setItem('VendorInfo', JSON.stringify(VendorInfo));
            // window.localStorage.setItem('VendorInfo', JSON.stringify(VendorInfo));
        }
    }, [verificationData, logins]);


    const handleOTPSubmit = async () => {
        const formattedOtp = otp.replace(/\s/g, '');
        console.log('Formatted OTP:', formattedOtp);
        
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
          setError('');
          
          const resultAction = await dispatch(verification({ email: signupEmail, verification_code: formattedOtp }));
          
          if (verification.fulfilled.match(resultAction)) {
            window.location.reload();
          } else {
            console.log('Verification failed:', resultAction.payload);
            setError('OTP verification failed. Please try again.');
          }
        } else {
          console.log('Invalid OTP:', formattedOtp);
          setError('OTP must be exactly 5 digits and must be a number.');
        }
      };

    const handleOtpChange = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtp(value.split('').join(' '));
        }
    };

    const handleIconClick = () => {

        window.sessionStorage.removeItem('VendorInfo');
        window.sessionStorage.removeItem('userEmail');
    
        setSignupEmail('');
        setSignupPassword('');
        setFirstName('');
        setLastName('');
        setLoginEmail('');
        setLoginPassword('');
        setOtp('');
        setOtpReser('');
        setEmailValid('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        setErrorReset('');
        setErrorResetPassword('');
        setLoginError('');
        setSignupError('');
        setLoginErrorReset('');
        setShowOTP(false);
        setShowToken(false);
        setShowEmail(false);
        setShowOtpContainer(false);
        setShowPasswordContainer(false);
    };
    

    // ForgotPassword
    const sendResetData = useSelector(state => state.Data.sendReset);
    const loadingSendReset = useSelector(state => state.Data.loadingSendReset);
    const errorSendReset = useSelector(state => state.Data.errorSendReset);
    
    const verifyResetData = useSelector(state => state.Data.verifyReset);
    const loadingVerifyReset = useSelector(state => state.Data.loadingVerifyReset);
    const errorVerifyReset = useSelector(state => state.Data.errorVerifyReset);

    const resetPasswordData = useSelector(state => state.Data.resetPassword);
    const loadingresetPassword = useSelector(state => state.Data.loadingresetPassword);
    const errorresetPassword = useSelector(state => state.Data.errorresetPassword);


    const isValidEmailReset = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [emailValid, setEmailValid] = useState('');
    const [errorResetPassword, setErrorResetPassword] = useState('');
    const [loginErrorReset, setLoginErrorReset] = useState('');
    const [otpReset, setOtpReser] = useState('');
    const [errorReset, setErrorReset] = useState('');
    const [isFormValidEmail, setIsFormValidEmail] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);

    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpContainer, setShowOtpContainer] = useState(false);
    const [showPasswordContainer, setShowPasswordContainer] = useState(false);
    const [showEmail, setShowEmail] = useState(false);

    useEffect(() => {
        const isValid = isValidEmailReset(emailValid);
        setIsFormValidEmail(isValid);
    }, [emailValid]);

    const handleSubmitEmail = () => {
        if (!isFormValidEmail) {
            setLoginErrorReset('Please fill in all fields with valid information.');
        } else {
            window.sessionStorage.setItem('userEmail', emailValid);
            // window.localStorage.setItem('userEmail', emailValid);

            dispatch(sendReset({email: emailValid }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowOtpContainer(true);
                    setShowEmail(false)
                }
            })
            .catch((error) => {

            });
        }
    };


    const [showPopup, setShowPopup] = useState(false);

    const handleSubmitPassword = () => {
        if (!Password || !confirmPassword) {
            setErrorResetPassword('يرجى إدخال كلمة المرور وتأكيدها.');
        } else if (!isValidPasswordsignup(Password)) {
            setErrorResetPassword('يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام.');
        } else if (Password !== confirmPassword) {
            setErrorResetPassword('كلمات المرور غير متطابقة.');
        } else {
            setErrorResetPassword('');
            setPasswordValid(true);
            // const storedEmail = window.localStorage.getItem('userEmail');
            const storedEmail = window.sessionStorage.getItem('userEmail');
            dispatch(resetPassword({ email: storedEmail, password: Password, password_confirmation: confirmPassword }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200) {
                        setShowPasswordContainer(false);
                        setActiveButton('login');
                        setShowPopup(true); // عرض البوب أب
                        setTimeout(() => setShowPopup(false), 4000); // إخفاء البوب أب بعد 3 ثواني
                    }
                })
                .catch((error) => {
           
                });
        }
    };

    useEffect(() => {
        if (Password && confirmPassword) {
            setPasswordValid(isValidPasswordsignup(Password) && Password === confirmPassword);
        } else {
            setPasswordValid(false);
        }
    }, [Password, confirmPassword]);

    

    const handleOTPSubmitReset = () => {
        const formattedOtp = otpReset.replace(/\s/g, '');
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
            setErrorReset('');
            // استخدام البريد الإلكتروني من localStorage عند إرسال OTP
            const storedEmail = window.sessionStorage.getItem('userEmail');
            dispatch(verifyReset({ email: storedEmail, verification_code: formattedOtp }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowPasswordContainer(true);
                    setShowOtpContainer(false)
                    setShowEmail(false)
                }
            })
            .catch((error) => {
            
            });
        } else {
            setErrorReset('OTP must be exactly 5 digits and must be a number.');
        }
    };

    const [isOtpValid, setIsOtpValid] = useState(false);
    useEffect(() => {
        // تحقق من أن الـ OTP يتكون من 5 أرقام
        const formattedOtp = otpReset.replace(/\s/g, '');
        setIsOtpValid(formattedOtp.length === 5 && !isNaN(formattedOtp));
    }, [otpReset]);

    const handleOtpChangeReset = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtpReser(value.split('').join(' '));
        }
    };

    const showReset = () => {
        setShowEmail(true)
    };


    const onDropSingle = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));
    if (imageFiles.length > 0) {
        setSingleImage(imageFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
        })));
        setErrorSingleImage('');
    } else {
        setErrorSingleImage('Please upload only images');
    }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({
    onDrop: onDropSingle,
    multiple: false
    });

  const [type, setType] = useState(["brand","store","influencer",]);
  const text5 = 'Select type'
  const[icon5, setIcon5] = useState(false)
  function handelClick5() {
    setIcon5(!icon5)
  }

    return (
        <div className="Register g-0">
            <div className='signup px-md-0 g-0'>
      <>

        {activeButton === 'signup' &&
                  <div className='row col-12 flex_reverse g-0'>
            <div className="cont col-12 col-sm-6 g-0 mb-0">
              <div className='createAccount mt-2'>
                <img src={group} alt="group" />
                <p className='font-size2'>{t('createAccount')}</p>
              </div>
                <form onSubmit={handleSubmit}>



                <div className={`div4 ${errors.productsNumber ? 'input-errors' : ''}`}>
                <input
                        placeholder='The number of products you will upload'
                        type="number"
                        onChange={(e) => setProductsNumber(e.target.value)}
                        value={productsNumber}
                    />
                </div>

                <div className={`box_AddCompany div4 ${errors.productsNumber ? 'input-errors' : ''}`} onClick={handelClick5}>

                    <div className="heade">
                        <span>{typeStore ? typeStore : text5}</span>
                        <i><FontAwesomeIcon icon={faChevronRight}/></i>
                    </div>
                    {icon5 &&
                        <div className="cat">
                            {type.map((e, i) => 
                                <p onClick={() => (setTypeStore(e), setIcon5(!icon5))}>{e}</p>
                            )}
                        </div>
                    }
                </div>
                <div className={`Add_image m-auto mt-3 ${errors.singleImage ? 'input-errors' : ''}`}>
                    <div className="adds" {...getRootPropsSingle()}>
                        <input {...getInputPropsSingle()} />
                        {!singleImage.length ? (
                            <div className="upload-placeholder">
                                <i className="fa fa-cloud-upload" />
                                <p>Click here to upload an logo</p>
                            </div>
                        ) : (
                            <div className="image-preview single">
                                {singleImage.map((file, index) => (
                                    <img 
                                        key={index} 
                                        src={file.preview} 
                                        alt={`upload preview ${index}`} 
                                        className="img" 
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                    <div className='div_lest'>
                        <p className='font-size1 '>{t('Eight Chars Min')}</p>
                        <p className='font-size1'>{t('One Letter')}</p>
                        <p className='font-size1'>{t('One Number')}</p>
                    </div>
                    {activeButton === 'signup' && signupError && <div className='error-message'>{signupError}</div>}
                    <div className='div6'>
                        <button
                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                            type="submit"
                        >
                            {t('CONTINUE')}
                        </button>
                    </div>

                </form>
                
            </div>
            <div className=' col-12 col-sm-6 cont'>
            <div className='logo_dash mt-3 display_none'>
                <img src={group} alt="group"/>
                <p>{t('Welcome to the new Experience')}</p>
            </div>
            <div className='div1 display_none'>
                <p>{t('Create your account')}</p>
            </div>
            {loadingRegister_vendor && <Loader />}
                <div className='div10 mt-4 mb-4'>
                    <p
                    className={activeButton === 'login' ? 'active' : ''}
                    onClick={() => handleButtonClick('login')}
                    >
                    {t('login')}
                    </p>
                    <p
                    className={activeButton === 'signup' ? 'active' : ''}
                    onClick={() => handleButtonClick('signup')}
                    >
                    {t('signup')}
                    </p>
                </div>

                <div className={`div3 ${errors.signupEmail ? 'input-errors' : ''}`}>
                        <input
                            placeholder={t('Enter email')}
                            onChange={(e) => setSignupEmail(e.target.value)}
                            className={errors.signupEmail ? 'input-errors' : ''}

                        />
                    </div>
                    <div className={`div4 ${errors.signupPassword ? 'input-errors' : ''}`}>
                    <input
                            placeholder={t('Password')}
                            type="password"
                            onChange={(e) => setSignupPassword(e.target.value)}
                        />
                    </div>

                    <div className={`div4 ${errors.companyName ? 'input-errors' : ''}`}>
                        <input
                            placeholder='Company Name'
                            type="text"
                            onChange={(e) => setCompanyName(e.target.value)}
                            value={companyName}
                        />
                    </div>

                    <div className={`div4 ${errors.number ? 'input-errors' : ''}`}>
                    <input type='text' id='number' name='number' value={number} onChange={(e) => setNumber(e.target.value)} placeholder='Phone'/>
                    </div>
                    <div className={`div4 ${errors.businessType ? 'input-errors' : ''}`}>
                        <input
                            placeholder='Your type of activity'
                            type="text"
                            onChange={(e) => setBusinessType(e.target.value)}
                            value={businessType}
                        />
                    </div>

                    <div className={`div12`}>
                    <textarea className={`${errors.address ? 'input-errors' : ''}`}
                    placeholder="the address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    />
                </div>

            </div>
          </div>
        }
      </>
            </div>
            {showToken && (
                <div className='container'>
                    <div className='row container_otp'>
                        <div className="cont col-11 col-sm-8 col-md-6 col-xl-4 ">
                            {loadingVerification && <Loader />}
                            <div className='icon'>
                                <i className='font-size2 ' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>

                            {error && <div className='error-message'>{error}</div>}
                            <div className='error-message'>{messageOTP?.message}</div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9" 
                                />
                            </div>
                            
                            <div className='div6'>
                                    <p
                                        className='continue-button valid'
                                        onClick={handleOTPSubmit}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='login col-12'>
                {activeButton === 'login' && (
                    <div className='row login_activ col-12 g-0'>
                        <div className="cont col-12 col-sm-6">
                            {loadingLogin && <Loader />}
                            <div className='logo_dash mt-5'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your account')}</p>
                            </div>
                            <div className='div10'>
                                <p
                                    className={activeButton === 'login' ? 'active' : ''}
                                    onClick={() => handleButtonClick('login')}
                                >
                                    {t('Log in')}
                                </p>
                                <p
                                    className={activeButton === 'signup' ? 'active' : ''}
                                    onClick={() => handleButtonClick('signup')}
                                >
                                    {t('Sign up')}
                                </p>
                            </div>
                            <form onSubmit={handleSubmit}>

                            <div className={`div3 ${errors_login.loginEmail ? 'input-errors' : ''}`}>
                            <input
                                            placeholder={t('Please enter email')}
                                            value={loginEmail}
                                            onChange={(e) => setLoginEmail(e.target.value)}
                                        />
                                </div>
                                <div className={`div3 ${errors_login.loginPassword ? 'input-errors' : ''}`}>
                                <input
                                            placeholder={t('Password')}
                                            type="password"
                                            value={loginPassword}
                                            onChange={(e) => setLoginPassword(e.target.value)}
                                        />
                                </div>
                                <div className='div5'>
                                    <p className='font-size1' onClick={showReset}>{t('Forgot Password')}</p>
                                </div>
                                {/* {activeButton === 'login' && loginError && <div className='error-message'>{loginError}</div>} */}
                                {errorLogin?.message && <div className='error-message'>{errorLogin?.message }</div>}

                                <div className='div6 mt-5'>
                                        <button
                                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                                            type="submit"
                                        >
                                            {t('CONTINUE')}
                                        </button>
                                </div>
                            </form>
                        </div>
                        <div className=" col-6 container_img">
                            <img src={image2}/>
                        </div>
                    </div>
                )}
            </div>

            {/* ForgotPassword */}
            {showEmail && (
               <div className='container'>
                    <div className='row container_otp'>
                        <div className="cont col-11 col-sm-8 col-md-6 col-xl-4 ">
                            {loadingSendReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div3 email mt-5'>
                                    <input
                                        placeholder={t('Please enter email')}
                                        onChange={(e) => setEmailValid(e.target.value)}
                                    />
                            </div>
                            <div className='mt mt-4'>
                                <div className='error-message'>{loginErrorReset}</div>
                                <div className='error-message'>{errorSendReset?.message?.message}</div>
                                <div className='div6'>
                                        <p
                                            className={isFormValidEmail ? 'continue-button valid' : 'continue-button'}
                                            onClick={handleSubmitEmail}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showOtpContainer && (
                <div className='container'>
                    <div className='row container_otp'>
                        <div className="cont col-11 col-sm-8 col-md-6 col-xl-4 ">
                            {loadingVerifyReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>
                            <div className='div14'>
                                <p>{t('Resend OTP')}</p>
                            </div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otpReset}
                                    onChange={handleOtpChangeReset}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9"
                                />
                            </div>

                            {otpReset && <div className='error-message'>{errorReset}</div>}
                            <div className='error-message'>{errorVerifyReset?.message}</div>

                            <div className='div6'>
                                    <p
                                        className={`continue-button ${isOtpValid ? 'valid' : ''}`}
                                        onClick={handleOTPSubmitReset}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showPasswordContainer && (
                <div className='container'>
                    <div className='row container_otp'>
                        <div className="cont col-11 col-sm-8 col-md-6 col-xl-4 ">                      
                            {loadingresetPassword && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo_dash'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your password')}</p>
                            </div>

                            <div className='div3 email'>
                                    <input
                                        placeholder={t('Password')}
                                        type="password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                            </div>

                            <div className='div3 email'>
                                    <input
                                        placeholder={t('Confirm Password')}
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                            </div>
                            <div className='mt-4'>
                                <div className='error-message'>{errorResetPassword}</div>
                                <div className='error-message'>{errorresetPassword?.message}</div>
                                <div className='div6'>
                                        <p
                                        className={`continue-button ${isPasswordValid ? 'valid' : ''}`}
                                            onClick={handleSubmitPassword}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className="popup_log">
                    <div className="popup-icon">
                        <i><FontAwesomeIcon icon={faCheck}/></i>
                    </div>
                    <p>{t('Password changed successfully')}</p>
                    <p>{t('Please log in now.')}</p>
                </div>
            )}

                    </div>
                );
            }

export default Register;
