import './Customers.css';
import './Vendor.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { all_vendors, vendor_status, vendor_details, update_vendor, delete_vendor } from "./Redux/API";
import { useTranslation } from 'react-i18next';
import Loader from './Loader';

function Vendor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteVendor = (id) => {
    dispatch(delete_vendor(id));
  };

  const vendor_status_data = useSelector(state => state.Data.vendor_status);
  const loadingVendor_status = useSelector(state => state.Data.loadingVendor_status);
  const loadingDelete_vendor = useSelector(state => state.Data.loadingDelete_vendor);


  const vendor_detail = useSelector(state => state.Data.vendor_details);
  const loadingVendor_details = useSelector(state => state.Data.loadingVendor_details);

  const handleVendor_details = (id) => {
    dispatch(vendor_details(id));
  };

  const update_vendors = useSelector(state => state.Data.update_vendor);
  const loadingUpdate_vendor = useSelector(state => state.Data.loadingUpdate_vendor);

  // const handleUpdate_vendor = (id) => {
  //   dispatch(update_vendor(id));
  // };

  const all_vendor = useSelector(state => state.Data.all_vendors);
  const loadingAll_vendor = useSelector(state => state.Data.loadingAll_vendors);

  const [searshVendors, setSearshVendors] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshVendors(e.target.value); 
  };

  useEffect(() => {
    dispatch(all_vendors(searshVendors));
  }, [dispatch, loadingUpdate_vendor, searshVendors]);

  
  const [selectedStatusM, setSelectedStatusM] = useState('Pending Approval'); 

  const handleStatusClick = (status) => {
    setSelectedStatusM(status);
    dispatch(vendor_status( status ));
  };

  useEffect(() => {
    dispatch(vendor_status(selectedStatusM));
}, [ dispatch, loadingUpdate_vendor, loadingDelete_vendor]);



  const gotoDataCustomers = () => {
    navigate('/DataVendor');
  };
  const [openCategory, setOpenCategory] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({});


  const handleCategoryClick = (id) => {
    setOpenCategory(openCategory === id ? null : id);
  };

  const handleSelectCategory = (id, category) => {
    setSelectedCategory({ ...selectedCategory, [id]: category });
    setOpenCategory(null);
    // إرسال البيانات المحدّثة للباك إند
    dispatch(update_vendor({
      id: id, 
      status: category
    }));
  };


  const listOrders = t('customers.vendor', { returnObjects: true });

  return (
    <div className="Customers">
      <Header />
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faUsers} /></i>
            <p>{t('customers.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
                {all_vendor?.data?.data?.vendor_status.map((e, i) => (
                <p 
                  key={i} 
                  onClick={() =>{handleStatusClick(e)}}
                  className={selectedStatusM === e ? 'selectedMethod' : ''} 
                  >
                  {e}
                </p>
              ))}    
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <input type="text" value={searshVendors} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />

            </div>
          </div>
        </div>

        <div className='container'>
          {(loadingUpdate_vendor || loadingVendor_status || loadingDelete_vendor) && <Loader />}
          <div className='Orders'>
            <div className='Orders_border text-center row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-3' key={index}>{e}</p>
              ))}
            </div>
            {searshVendors
            ? (
                all_vendor?.data?.data?.users?.map((vendor) => (
                  <div key={vendor.id}>
                    <div className='Orders_border hover row justify-content-around'>
                      <div className='aa col-3' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>
                        <img src={vendor.user_image} alt="customer" />
                        <div>
                          <p className='p'>{vendor.name}</p>
                          <p className='p'>{vendor.email}</p>
                        </div>
                      </div>
                      <p className='col-3 text-center' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>{vendor.phone_number}</p>
                      <p className='col-3 text-center' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>{vendor.created_at}</p>
                      <p className='col-3 text-center d-flex align-items-center'>
                        <div className="box_add" onClick={() => handleCategoryClick(vendor.id)}>
                          <div className="heade">
                            <span>{selectedCategory[vendor.id] || vendor.status}</span>
                            <i><FontAwesomeIcon icon={faChevronRight} /></i>
                          </div>
                          {openCategory === vendor.id &&
                            <div className="cat2">
                              {all_vendor?.data?.data?.vendor_status.map((e, i) =>
                                  <p key={i} onClick={() => handleSelectCategory(vendor.id, e)}>{e}</p>
                                )}
                            </div>
                          }
                        </div>
                        <span className='span_icon2' onClick={() => handleDeleteVendor(vendor.id)}><FontAwesomeIcon icon={faTrash} /></span>

                      </p>
                    </div>
                  </div>
                ))

              )
              :
              (
                vendor_status_data?.data?.data?.map((vendor) => (
                  <div key={vendor.id}>
                    <div className='Orders_border hover row justify-content-around'>
                      <div className='aa col-3' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>
                        <img src={vendor.user_image} alt="customer" />
                        <div>
                          <p className='p'>{vendor.name}</p>
                          <p className='p'>{vendor.email}</p>
                        </div>
                      </div>
                      <p className='col-3 text-center' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>{vendor.phone_number}</p>
                      <p className='col-3 text-center' onClick={() => { gotoDataCustomers(); handleVendor_details(vendor.id); }}>{vendor.created_at}</p>
                      <p className='col-3 text-center d-flex align-items-center'>
                        <div className="box_add" onClick={() => handleCategoryClick(vendor.id)}>
                          <div className="heade">
                            <span>{selectedCategory[vendor.id] || vendor.status}</span>
                            <i><FontAwesomeIcon icon={faChevronRight} /></i>
                          </div>
                          {openCategory === vendor.id &&
                            <div className="cat2">
                              {all_vendor?.data?.data?.vendor_status.map((e, i) =>
                                  <p key={i} onClick={() => handleSelectCategory(vendor.id, e)}>{e}</p>
                                )}
                            </div>
                          }
                        </div>
                        <span className='span_icon2' onClick={() => handleDeleteVendor(vendor.id)}><FontAwesomeIcon icon={faTrash} /></span>

                      </p>
                    </div>
                  </div>
                ))
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Vendor;