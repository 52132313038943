import React, { useState, useCallback } from 'react';
import './Profile.css';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload } from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';
import Header from './Header/Header';
import { update_profile, vendor_profie } from "./Redux/API";
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from './Loader';

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const vendor_profie_data = useSelector(state => state.Data.vendor_profie);

  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [activityType, setActivityType] = useState('');
  const [address, setAddress] = useState('');
  const [numberOfProducts, setNumberOfProducts] = useState('');
  const [typeStore, setTypeStore] = useState('');
  const [singleImage, setSingleImage] = useState(null);

  useEffect(() => {
    if (vendor_profie_data?.data?.data) {
      setId(vendor_profie_data.data.data.id || '');
      setName(vendor_profie_data.data.data.vendor_info?.created_at || '');
      setPhone(vendor_profie_data.data.data.phone_number || '');
      setEmail(vendor_profie_data.data.data.email || '');
      setCompanyName(vendor_profie_data.data.data.vendor_info?.company || '');
      setActivityType(vendor_profie_data.data.data.vendor_info?.activity_type || '');
      setAddress(vendor_profie_data.data.data.vendor_info?.address || '');
      setNumberOfProducts(vendor_profie_data.data.data.vendor_info?.number_of_products || '');
      setTypeStore(vendor_profie_data.data.data.vendor_info?.category || '');
      setSingleImage(vendor_profie_data.data.data.user_image || null);
    }
  }, [vendor_profie_data]);
  




  const onDropSingle = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSingleImage(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ 
    onDrop: onDropSingle, 
    multiple: false 
  });

  const loadingUpdate_profile = useSelector(state => state.Data.loadingUpdate_profile);
  console.log(vendor_profie_data?.data.data)

  useEffect(() => {
    dispatch(vendor_profie());
  }, [dispatch, loadingUpdate_profile]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const Image = singleImage instanceof File ? singleImage : null;

    dispatch(update_profile({
      company: companyName,
      email,
      phone_number: phone,
      activity_type: activityType,
      address,
      number_of_products: numberOfProducts,
      category: typeStore,
      image: Image,
      id
    }));
  };

  return (
    <div className="Profile">
      <Header />
      {loadingUpdate_profile && <Loader />}
      <div className='container-fluid mt3'>
        <form className="profile-card" onSubmit={handleSubmit}>
          <div className="col-4 mt-3">
            <div className="Add_img">
              <div className="add" {...getRootPropsSingle()}>
                <input {...getInputPropsSingle()} />
                {!singleImage && (
                  <div className="upload-placeholder">
                    <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                    <p>{t('profile.drag_and_drop')}</p>
                  </div>
                )}
                {singleImage && (
                  <img 
                    src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} 
                    alt="Preview" 
                    className="img" 
                  />
                )}
              </div>
            </div>
          </div>
          <div className="profile-info mt-3">

            <div className="form-group">
              <label htmlFor="companyName">{t('profile.company_name')}</label>
              <input
                type="text"
                id="companyName"
                name="CompanyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">{t('profile.email')}</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">{t('profile.registration_date')}</label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="phone">{t('profile.phone')}</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="activityType">{t('profile.activity_type')}</label>
              <input
                type="text"
                id="activityType"
                name="Activity_type"
                value={activityType}
                onChange={(e) => setActivityType(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="address">{t('profile.address')}</label>
              <input
                type="text"
                id="address"
                name="address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="numberOfProducts">{t('profile.number_of_products')}</label>
              <input
                type="number"
                id="numberOfProducts"
                name="Number_of_products"
                value={numberOfProducts}
                onChange={(e) => setNumberOfProducts(e.target.value)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="typeStore">{t('profile.category')}</label>
              <input
                type="text"
                id="typeStore"
                name="category"
                value={typeStore}
                onChange={(e) => setTypeStore(e.target.value)}
                readOnly
              />
            </div>
            <button type="submit" className="save-button mb-3">{t('profile.save_changes')}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
