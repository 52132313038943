import './Customers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrash, faUsers } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { get_customers, delete_customer } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
import { CustomerDetails } from "./Redux/HomeSlice";


function Customers() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const get_customer = useSelector(state => state.Data.get_customers);
  const loadingGet_customers = useSelector(state => state.Data.loadingGet_customers);
  const loadingDelete_customer = useSelector(state => state.Data.loadingDelete_customer);

  useEffect(() => {
    dispatch(get_customers())

  }, [dispatch, loadingDelete_customer])

  const handleDeleteCustomer = (id) => {
    dispatch(delete_customer(id));
  };

  const listOrders = t('customers.columns', { returnObjects: true });

  
  const gotoDataCustomers = (user) => {
    if (!user || !user.id) {
      console.error('Product data is missing or invalid');
      return;
    }
    dispatch(CustomerDetails(user));
    navigate('/DataCustomers');
  };

  return (
    <div className="Customers">
      <Header />
      {loadingGet_customers && <Loader />}
      {loadingDelete_customer && <Loader />}
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faUsers} /></i>
            <p>{t('customers.title')}</p>
          </div>
        </div>

        <div className='container mt-3'>
          <div className='row'>
            <div className='sarsh'>
              {/* <input placeholder={t('customers.search_placeholder')} /> */}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-2 text-center' key={index}>{e}</p>
              ))}
            </div>

            {get_customer?.data?.data?.users.map((user) => (
              <div key={user.id}>
                {loadingGet_customers && <Loader />}
                <div className='Orders_border hover row justify-content-around cursor_pointer'>
                  <div className='aa col-2 text-center' onClick={() => gotoDataCustomers(user)}>
                    <img src={user.user_image} alt="customer" />

                    <div>
                      <p className='p'>{user.first_name}</p>
                    </div>
                  </div>
                  <p className='col-2 text-center' onClick={() => gotoDataCustomers(user)}>{user.phone_number}</p>
                  <p className='col-2 text-center' onClick={() => gotoDataCustomers(user)}>{user.last_login_date}</p>
                  <p className='col-2 text-center' onClick={() => gotoDataCustomers(user)}>{user.email}</p>
                  <p className='col-2 text-center'>
                    <span 
                      className='span_icon2' 
                      onClick={() => handleDeleteCustomer(user.id)} 
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </span>                  
                  </p>

                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Customers;