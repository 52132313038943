import './Transactions.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { transactions_data, payment_status, paymen_methods } from "./Redux/API";
import Loader from './Loader';
import { useEffect } from 'react';

function Transactions() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const transactions_datas = useSelector(state => state.Data.transactions_data);
  const loadingTransactions_data = useSelector(state => state.Data.loadingTransactions_data);

  const payment_statu = useSelector(state => state.Data.payment_status);
  const loadingPayment_status = useSelector(state => state.Data.loadingPayment_status);


  const paymen_method = useSelector(state => state.Data.paymen_methods);

  useEffect(() => {
    dispatch(transactions_data()); 
    dispatch(paymen_methods()); 
}, []);




  const [selectedMethod, setSelectedMethod] = useState('');

  const handlePaymentMethodClick = (methodName) => {
    setSelectedMethod(methodName); 
    dispatch(payment_status(methodName))
  };

  const [mm, setmm] = useState([])
  const listOrders = t('transactions.columns', { returnObjects: true });

  return (
    <div className="Transactions">

      <Header />
      
      <div className='container-fluid'>
        {loadingPayment_status && <Loader />}
        {loadingTransactions_data && <Loader />}
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faCalendarCheck} /></i>
            <p>{t('transactions.title')}</p>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='Pending'>
              {paymen_method?.data?.data.map((e, i) => (
                <p
                  key={i}
                  onClick={() => handlePaymentMethodClick(e.method_name)}
                  className={selectedMethod === e.method_name ? 'selectedMethod' : ''} 
                >
                  {e.method_name}
                </p>
              ))}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              {/* <input placeholder={t('transactions.search_placeholder')} /> */}
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='Orders'>
            <div className='Orders_border row justify-content-around'>
              {listOrders.map((e, index) => (
                <p className='col-1 text-center border-0' key={index}>{e}</p>
              ))}
            </div>

            {payment_statu?.data?.data?.length > 0 ? (
              payment_statu?.data?.data.map((order) => (
                <div key={order.id}>
                  <div className='div_Transactions'>
                    <p className='col-2'>{order.id}</p>
                    <p className='col-2'>{order.user_name}</p>
                    <p className='col-2'>{order.transaction_date}</p>
                    <p className='col-2'>{order.payment_method}</p>
                    <p className='col-2'>{order.amount}</p>
                    <p className='col-2'>{order.payment_status}</p>
                  </div>
                </div>
              ))
            ) : (
              transactions_datas?.data?.data.map((order) => (
                <div key={order.id}>
                  <div className='div_Transactions'>
                    <p className='col-2'>{order.id}</p>
                    <p className='col-2'>{order.user_name}</p>
                    <p className='col-2'>{order.transaction_date}</p>
                    <p className='col-2'>{order.payment_method}</p>
                    <p className='col-2'>{order.amount}</p>
                    <p className='col-2'>{order.payment_status}</p>
                  </div>
                </div>
              ))
            )}
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transactions;