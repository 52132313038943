import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { 
    faHouse, 
    faBookmark, 
    faMessage, 
    faUser, 
    faCalendarCheck, 
    faArrowRightFromBracket,
    faShoppingCart, 
    faTags, 
    faBullhorn, 
    faBoxOpen, 
    faChartPie, 
    faTrademark,
    faPlusCircle,
    faInfoCircle,
    faEnvelope,
    faClipboardList,
    faAd,
    faBell,
    faCartPlus,
    faUsers,
    faHeart,
    faStore,
    faUserTie,
    faTicketAlt,
    faFileAlt,
    faTag,
    faPercentage
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Sidebar.css';
import { faListAlt } from '@fortawesome/free-solid-svg-icons/faListAlt';
import { useNavigate } from 'react-router-dom';

function Sidebar(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const gotoeRegister = (item) => {
        // window.localStorage.removeItem('VendorInfo'); 
        // window.localStorage.removeItem('languages'); 
        window.sessionStorage.removeItem('VendorInfo'); 
        window.sessionStorage.removeItem('languages'); 
        navigate('/')
        window.location.reload()
    };


      
    const getLocalStorage = window.sessionStorage.getItem('VendorInfo');
    // const getLocalStorage = window.localStorage.getItem('VendorInfo');
    // تحليل البيانات إذا كانت موجودة
    const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

    const [language, setLanguage] = useState(sessionStorage.getItem('language') || 'en');
    // const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
    const [showadmin, setShowadmin] = useState(valueLocalStorage?.data?.data?.user?.role);
    
    // console.log(valueLocalStorage?.data)

    useEffect(() => {
        i18n.changeLanguage(language);
        document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
        document.body.style.textAlign = language === 'ar' ? 'right' : 'left';
    }, [language, i18n]);

    return (
        <div className='Sidebar'>
            {showadmin === 'admin' ?
                <PerfectScrollbar>
                    <ul>
                            <li>
                                <NavLink className="link" to="/"><i><FontAwesomeIcon icon={faChartPie} /></i> {t('sidebar.statistics')}</NavLink>
                            </li>
                        <li>
                            <NavLink className="link" to="/CustomerReports"><i><FontAwesomeIcon icon={faFileAlt} /></i> {t('sidebar.customer_reports')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/InventoryReports"><i><FontAwesomeIcon icon={faFileAlt} /></i> {t('sidebar.inventory_reports')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/OrdersManagement"><i><FontAwesomeIcon icon={faShoppingCart} /></i> {t('sidebar.orders_management')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Customers"><i><FontAwesomeIcon icon={faUsers} /></i> {t('sidebar.customers')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Categoires"><i><FontAwesomeIcon icon={faTags} /></i> {t('sidebar.categories')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Vendor"><i><FontAwesomeIcon icon={faUserTie} /></i> {t('sidebar.vendor')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Transactions"><i><FontAwesomeIcon icon={faCalendarCheck} /></i> {t('sidebar.transactions')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Adveritsement"><i><FontAwesomeIcon icon={faAd} /></i> {t('sidebar.advertisement')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/ProductsList"><i><FontAwesomeIcon icon={faListAlt} /></i> {t('sidebar.products_list')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Favorites"><i><FontAwesomeIcon icon={faHeart} /></i> {t('sidebar.favorites')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/Cart"><i><FontAwesomeIcon icon={faCartPlus} /></i> {t('sidebar.cart')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/AddAllCoupon"><i><FontAwesomeIcon icon={faTicketAlt} /></i> {t('sidebar.create_coupon')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/AddCouponAdmin"><i><FontAwesomeIcon icon={faTicketAlt} /></i> {t('sidebar.add_coupon')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/CreateDiscount"><i><FontAwesomeIcon icon={faPercentage} /></i>{t('sidebar.create_discount')}</NavLink>
                        </li>
                        <li>
                            <NavLink className="link" to="/AddDiscountAdmin"><i><FontAwesomeIcon icon={faPercentage} /></i>{t('sidebar.add_discount')}</NavLink>
                        </li>
                            <button onClick={gotoeRegister}>{t('sidebar.logout')}<i><FontAwesomeIcon icon={faArrowRightFromBracket} /></i> </button>
                    </ul>
                </PerfectScrollbar> 
            :

                <ul>
                    <li>
                        <NavLink className="link" to="/"><i><FontAwesomeIcon icon={faChartPie} /></i> {t('sidebar.statistics')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/VendorOrderManagement"><i><FontAwesomeIcon icon={faShoppingCart} /></i> {t('sidebar.orders_management')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/AddProduct"><i><FontAwesomeIcon icon={faPlusCircle} /></i> {t('sidebar.add_product')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/VendorProduct"><i><FontAwesomeIcon icon={faListAlt} /></i> {t('sidebar.products_list')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/CategorysVendors"><i><FontAwesomeIcon icon={faTags} /></i> {t('sidebar.categories')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/ProductReports"><i><FontAwesomeIcon icon={faFileAlt} /></i> {t('sidebar.product_reports')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/AddAllCoupon"><i><FontAwesomeIcon icon={faTicketAlt} /></i> {t('sidebar.create_coupon')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/AddCoupon"><i><FontAwesomeIcon icon={faTicketAlt} /></i> {t('sidebar.add_coupon')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/CreateDiscount"><i><FontAwesomeIcon icon={faPercentage} /></i> {t('sidebar.create_discount')}</NavLink>
                    </li>
                    <li>
                        <NavLink className="link" to="/AddDiscount"><i><FontAwesomeIcon icon={faPercentage} /></i> {t('sidebar.add_discount')}</NavLink>
                    </li>
                    <button onClick={gotoeRegister} style={{ position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }} className="btn btn-primary">
                        {t('sidebar.logout')}
                        <i><FontAwesomeIcon icon={faArrowRightFromBracket} /></i>
                    </button>                    
                </ul>
            }
        </div>
    );
}

export default Sidebar;