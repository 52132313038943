import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const getLanguageFromStorage = () => {
  return localStorage.getItem('language') || 'en'; // إذا لم توجد لغة في التخزين، سيكون الافتراضي 'en'
};
axios.interceptors.request.use((config) => {
  const language = getLanguageFromStorage();
  config.headers['Accept-Language'] = language;
  return config;
}, (error) => {
  return Promise.reject(error);
});

// هتجيب البيانات المخزنة من الـ localStorage بشكل مباشر
// const getLocalStorage = window.localStorage.getItem('VendorInfo');
const getLocalStorage = window.sessionStorage.getItem('VendorInfo');

const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;

const token = valueLocalStorage && valueLocalStorage.data 
  ? (valueLocalStorage?.data?.token || 
     (valueLocalStorage?.data?.data
    //  (valueLocalStorage?.data?.data && valueLocalStorage.data.data.user 
      ? valueLocalStorage?.data?.data?.token 
      : null)) 
  : null;
  
console.log(token)
const apiUrl = "https://sub.api.ashtiri.com/";

export const addUser = createAsyncThunk('user/add', async ({ first_name, last_name, password, email, role }, thunkAPI) => {
  console.error(first_name, last_name, password, email, role );
 
  try {
    const response = await axios.post(`${apiUrl}api/register`, { first_name, last_name, password, email, role });
    console.error(response.data);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);

    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const verification = createAsyncThunk('user/verification', async ({ email, verification_code }, thunkAPI) => {
  try {
    console.log(email, verification_code);
    const response = await axios.post(`${apiUrl}api/verify-email`, { email, verification_code });

    if (response.status === 200) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue('Verification failed');
    }
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const login = createAsyncThunk('user/login', async ({ email, password },  { rejectWithValue }) => {

  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  
        'Content-Type': 'multipart/form-data' 
      },
    };

    const response = await axios.post(`${apiUrl}api/login`, { email, password }, config);
      const success = response.status >= 200 && response.status < 300;
      // console.error(response)

      return { data: response.data, status: response.status, success };
  } catch (error) {
    if (error.response) {
      // console.error(error.response)

        return rejectWithValue({ ...error.response.data, success: false }); 
    } else {
        return rejectWithValue({ message: error.message, success: false }); 
    }
  }
});


export const sendReset = createAsyncThunk('user/sendReset', async ({email}, thunkAPI) => {
  try {
    const response = await axios.post(`${apiUrl}api/send-reset-password-code`, {email });
    return { data: response.data, status: response.status };

  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const verifyReset = createAsyncThunk('user/verifyReset', async ({email, verification_code}, thunkAPI) => {
  try {

    const response = await axios.post(`${apiUrl}api/verify-reset-password-code`, {email, verification_code });

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const resetPassword = createAsyncThunk('user/resetPassword', async ({email, password, password_confirmation}, thunkAPI) => {
  try {
    const response = await axios.post(`${apiUrl}api/reset-password`, {email, password, password_confirmation});
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const get_customers = createAsyncThunk('user/customers', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/customers`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const favorite_products = createAsyncThunk('user/favorite_products', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/favorite-products`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const cart_data = createAsyncThunk('user/cart_data', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/cart-data`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const low_stock = createAsyncThunk('user/low_stock', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/low-stock`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const searsh_product = createAsyncThunk('user/searsh_product', async (search, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', 
      }
    };

    const response = await axios.get(`${apiUrl}api/product?search=${search}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const searsh_order = createAsyncThunk('user/searsh_order', async (search, { rejectWithValue }) => {
  console.log(search)
  try {
    const config = {
      headers: {
        Authorization: token ? `Bearer ${token}` : '', 
      }
    };

    const response = await axios.get(`${apiUrl}api/orders/search/${search}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const customers_report = createAsyncThunk('user/customers_report', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/customers-report`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const inventory_report = createAsyncThunk('user/inventory_report', async (aut, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/inventory-report`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const sales_report = createAsyncThunk('user/sales_report', async ({start_date, end_date}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
        start_date,
        end_date
      }
    };
    
    const response = await axios.get(`${apiUrl}api/sales-report`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const user_distribution = createAsyncThunk('user/user_distribution', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/user-distribution`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const address_distribution = createAsyncThunk('user/address_distribution', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/address-distribution`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const shipped_orders_count = createAsyncThunk('user/shipped_orders_count', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/shipped-orders-count`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});


export const display_brands = createAsyncThunk('user/display_brands', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/brands`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const order_items = createAsyncThunk('user/order_items', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/orders`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const product = createAsyncThunk('user/product', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/product`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const category = createAsyncThunk('user/category', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/category`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const ads = createAsyncThunk('user/ads', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/ads`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const transactions_data = createAsyncThunk('user/transactions_data', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/transactions-data`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const payment_status = createAsyncThunk('user/payment_status', async (methodName, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/payment/method/${methodName}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const paymen_methods = createAsyncThunk('user/paymen_methods', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    const response = await axios.get(`${apiUrl}api/payment-methods`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const all_vendors = createAsyncThunk('user/all_vendors', async (search, { rejectWithValue }) => {
  console.log(search)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
        search
      }
    };
    
    const response = await axios.get(`${apiUrl}api/all-vendors`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const vendor_status = createAsyncThunk('user/vendor_status', async (method, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    

    const response = await axios.get(`${apiUrl}api/vendor/status/${method}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message); 
    }
  }
});

export const vendor_details = createAsyncThunk('user/vendor_details', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/vendor-details/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data); 
    } else {
      return rejectWithValue(error.message); 
    }
  }
});


export const update_vendor = createAsyncThunk('user/update_vendor', async ({id, status}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.put(`${apiUrl}api/vendor/${id}/status`,{status: status}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const orders_status = createAsyncThunk('user/orders_status', async (status, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.get(`${apiUrl}api/orders/status/${status}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const update_orders = createAsyncThunk('user/update_orders', async ({id, status}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.put(`${apiUrl}api/orders/${id}/status`,{status: status}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const add_coupon = createAsyncThunk('user/add_coupon', async ({product_ids, coupon_id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
    };
    
    const response = await axios.post(`${apiUrl}api/add-product-coupon`,{product_ids, coupon_id}, config);
    const success = response.status >= 200 && response.status < 300;

    return { data: response.data, status: response.status, success };
} catch (error) {
    if (error.response) {
        return rejectWithValue({ ...error.response.data, success: false }); 
    } else {
        return rejectWithValue({ message: error.message, success: false }); 
    }
}
});

export const remove_product_coupon = createAsyncThunk('user/remove_product_coupon', async ({product_ids, coupon_id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
    };
    
    const response = await axios.post(`${apiUrl}api/remove-product-coupon`,{product_ids, coupon_id}, config);
    const success = response.status >= 200 && response.status < 300;

    return { data: response.data, status: response.status, success };
} catch (error) {
    if (error.response) {
        return rejectWithValue({ ...error.response.data, success: false }); 
    } else {
        return rejectWithValue({ message: error.message, success: false }); 
    }
}
});



export const add_discount = createAsyncThunk('user/add_discount', async ({product_ids, discount_id}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.post(`${apiUrl}api/add-discount-to-product`,{product_ids, discount_id}, config);
    const success = response.status >= 200 && response.status < 300;

    return { data: response.data, status: response.status, success };
    } catch (error) {
    if (error.response) {
        return rejectWithValue({ ...error.response.data, success: false }); 
    } else {
        return rejectWithValue({ message: error.message, success: false }); 
    }
}
});
export const remove_discount_product = createAsyncThunk('user/remove_discount_product', async ({product_ids}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.post(`${apiUrl}api/remove-discount-from-product`,{product_ids}, config);
    const success = response.status >= 200 && response.status < 300;

    return { data: response.data, status: response.status, success };
    } catch (error) {
    if (error.response) {
        return rejectWithValue({ ...error.response.data, success: false }); 
    } else {
        return rejectWithValue({ message: error.message, success: false }); 
    }
}
});



export const all_coupon = createAsyncThunk('user/all_coupon', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    const response = await axios.get(`${apiUrl}api/coupon`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const invoice = createAsyncThunk('user/invoice', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    const response = await axios.get(`${apiUrl}api/invoice/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const create_coupon = createAsyncThunk('user/create_coupon', async (formData, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.post(`${apiUrl}api/coupon`,{discount_id : formData.discount_id, code : formData.code, usage_limit : formData.usage_limit, expiry_date : formData.expiry_date}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const delete_coupon = createAsyncThunk('user/delete_coupon', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/coupon/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const create_discount = createAsyncThunk('user/create_discount', async (formData, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    const response = await axios.post(`${apiUrl}api/discount`,{name : formData.code, discount_value : formData.discount_value, is_active : formData.usage_limit, discount_type : formData.expiry_date}, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const all_discount = createAsyncThunk('user/all_discount', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    const response = await axios.get(`${apiUrl}api/discount`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const delete_discount = createAsyncThunk('user/delete_discount', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/discount/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});




export const delete_customer = createAsyncThunk('user/delete_customer', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/customer/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});



export const delete_category = createAsyncThunk('user/delete_category', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/category/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const delete_brands = createAsyncThunk('user/delete_brands', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/brands/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});
export const delete_product = createAsyncThunk('user/delete_product', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/product/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const delete_ad = createAsyncThunk('user/delete_ad', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/ad/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});

export const delete_vendor = createAsyncThunk('user/delete_vendor', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.delete(`${apiUrl}api/vendor/${id}`, config);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const post_ads = createAsyncThunk('user/post_ads', async (adData, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  
        'Content-Type': 'multipart/form-data' 
      },
    };
    const response = await axios.post(`${apiUrl}api/ads`,{title: adData.name, description: adData.description, image_url: adData.singleImage, link: adData.link, category_id: adData.category_id, vendor_id: adData.vendor_id} , config);
      const success = response.status >= 200 && response.status < 300;

      return { data: response.data, status: response.status, success };
  } catch (error) {
      if (error.response) {
          return rejectWithValue({ ...error.response.data, success: false }); 
      } else {
          return rejectWithValue({ message: error.message, success: false }); 
      }
  }
});

export const post_category = createAsyncThunk('user/post_category', async ({taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  
        'Content-Type': 'multipart/form-data'  
      },
    };

    const response = await axios.post(`${apiUrl}api/category`, {name_ar: taskNameAr, name_en: taskNameEn, description_en: descriptionEn, description_ar: descriptionAr, image:singleImage, parent_id: parentId}, config);
      const success = response.status >= 200 && response.status < 300;

      return { data: response.data, status: response.status, success };
  } catch (error) {
      if (error.response) {
          return rejectWithValue({ ...error.response.data, success: false }); 
      } else {
          return rejectWithValue({ message: error.message, success: false }); 
      }
  }
});

export const product_status = createAsyncThunk('user/product_status', async (_, { rejectWithValue }) => {


  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  
      }
    };
    const response = await axios.get(`${apiUrl}api/product-status`, config);

    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      return rejectWithValue(error.response.data);
    } else {
      return rejectWithValue(error.message);
    }
  }
});


export const archive_status = createAsyncThunk('user/archive_status', async (action, { rejectWithValue }) => {
  

  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  
      }
    };
    const response = await axios.get(`${apiUrl}api/product/archive-status/${action}`, config);

    console.log({data: response.data})
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

export const update_status = createAsyncThunk('user/update_status', async ({id, status}, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}` 
      },
      params: {
      }
    };
    
    const response = await axios.put(`${apiUrl}api/product/${id}/archive-status`,{archive_status: status}, config);
    console.log({data: response.data})
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});









export const visitors_stats = createAsyncThunk('user/visitors_stats', async ({start_date}, { rejectWithValue }) => {

console.log(start_date)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {
        start_date,
      }
    };
    const response = await axios.get(`${apiUrl}api/visitors-stats`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

export const vendor_sales_report = createAsyncThunk('user/vendor_sales_report', async (action, { rejectWithValue }) => {


  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/vendor/sales-report`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});



export const get_revenues = createAsyncThunk('user/get_revenues', async ({start_date, comparison_date}, { rejectWithValue }) => {


  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {
        start_date,
        comparison_date
      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/get-revenues`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});


export const main_statistics = createAsyncThunk('user/main_statistics', async ({start_date, comparison_date}, { rejectWithValue }) => {


  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {
        start_date,
        comparison_date
      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/main-statistics`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});



export const vendor_profie = createAsyncThunk('user/vendor_profie', async (_, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },

    };
    const response = await axios.get(`${apiUrl}api/vendor-profile`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});


export const inventory_overview = createAsyncThunk('user/inventory_overview', async (_, { rejectWithValue }) => {


  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {

      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/inventory-overview`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});
export const vendor_products = createAsyncThunk('user/vendor_products', async (search, { rejectWithValue }) => {
  console.log(search)
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {
        search
      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/vendor/products`, config);
    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

export const category_add_product = createAsyncThunk('user/category_add_product', async () => {
  try {
    const response = await axios.get(`${apiUrl}api/category`);
    console.error(response.data);
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
    } else {
      console.error("Error:", error.message);
    }
  }
});

export const show_category = createAsyncThunk('user/show_category', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',  // لضمان إرسال الملف كملف صورة
      },
      params: {

      }
    };
    // إرسال البيانات للـ backend
    const response = await axios.get(`${apiUrl}api/category/${id}`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

export const notifications = createAsyncThunk('user/notifications', async (id, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      params: {

      }
    };
    const response = await axios.get(`${apiUrl}api/user/notifications`, config);

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});


export const register_vendor = createAsyncThunk('user/register_vendor', async ({email, password, company_name, phone, business_type, products_number, address, type_store, image, role }, { rejectWithValue }) => {
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  // التوكن لو كان مطلوباً
        'Content-Type': 'multipart/form-data'  // التأكد من أن الهيدر مناسب لإرسال ملفات
      },
    };

    const response = await axios.post(`${apiUrl}api/register-vendor`, {email: email, password: password, company: company_name, phone_number: phone, activity_type: business_type, number_of_products: products_number, address: address, category: type_store, image: image, role: role }, config);
    console.log({data: response.data});
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

// export const update_profile = createAsyncThunk('user/update_profile', async ({id, company, email, phone_number, activity_type, address, number_of_products, category, image}, { rejectWithValue }) => {
//   console.log({id, company, email, phone_number, activity_type, address, number_of_products, category, image})
//   console.log(token)
//   try {
//     const config = {
//       headers: {
//         'Authorization': `Bearer ${token}`,  // التوكن لو كان مطلوباً
//       },
//     };

//     const response = await axios.post(`https://api.ashtiri.com/api/vendor/update-profile/${id}`, {company, email, phone_number, activity_type, address, number_of_products, category, image}, config);
//     console.log({data: response.data});
//     return { data: response.data, status: response.status };
//   } catch (error) {
//     if (error.response) {
//       console.error("Server responded with:", error.response.data);
//       return rejectWithValue(error.response.data);
//     } else {
//       console.error("Error:", error.message);
//       return rejectWithValue(error.message);
//     }
//   }
// });

export const update_profile = createAsyncThunk('user/update_profile', async ({id, company, email, phone_number, activity_type, address, number_of_products, category, image}, { rejectWithValue }) => {
  console.log({id, company, email, phone_number, activity_type, address, number_of_products, category, image});
  console.log(token);

  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  // التوكن لو كان مطلوباً
        'Content-Type': 'multipart/form-data', // ضروري عند استخدام FormData
      },
    };

    // إنشاء FormData وإضافة البيانات إليه
    const formData = new FormData();
    formData.append('company', company);
    formData.append('email', email);
    formData.append('phone_number', phone_number);
    formData.append('activity_type', activity_type);
    formData.append('address', address);
    formData.append('number_of_products', number_of_products);
    formData.append('category', category);

    // إذا كان لديك صورة مرفقة
    if (image) {
      formData.append('image', image); // تأكد من أن `image` إما ملف أو Blob
    }

    // إرسال الطلب
    const response = await axios.post(
      `${apiUrl}api/vendor/update-profile/${id}`,
      formData,
      config
    );

    console.log({ data: response.data });
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});



export const edit_category = createAsyncThunk('user/edit_category', async ({id, taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId }, { rejectWithValue }) => {
  console.log(id, taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId)
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  // التوكن لو كان مطلوباً
        'Content-Type': 'multipart/form-data'  // التأكد من أن الهيدر مناسب لإرسال ملفات
      },
    };

    const response = await axios.post(`${apiUrl}api/category/${id}`, {name_ar: taskNameAr, name_en: taskNameEn, description_en: descriptionEn, description_ar: descriptionAr, image :singleImage, parent_id: parentId }, config);
    // const response = await axios.post(`https://api.ashtiri.com/api/category/${id}`, formData, config);
    console.log({data: response.data});
    return { data: response.data, status: response.status };
  } catch (error) {
    if (error.response) {
      console.error("Server responded with:", error.response.data);
      return rejectWithValue(error.response.data);
    } else {
      console.error("Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
});

// export const edit_category = createAsyncThunk('user/edit_category', async ({ id, taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId }, { rejectWithValue }) => {
//   try {
//     const config = {
//       headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'multipart/form-data',
//       },
//     };

//     const formData = new FormData();
//     formData.append('name_ar', taskNameAr);
//     formData.append('name_en', taskNameEn);
//     formData.append('description_en', descriptionEn);
//     formData.append('description_ar', descriptionAr);
//     formData.append('parent_id', parentId);

//     // أضف الصورة فقط إذا كانت موجودة
//     if (singleImage) {
//       formData.append('image', singleImage instanceof File ? singleImage : '');
//     }

//     const response = await axios.post(`https://api.ashtiri.com/api/category/${id}`, formData, config);
//     return { data: response.data, status: response.status };
//   } catch (error) {
//     if (error.response) {
//       return rejectWithValue(error.response.data);
//     } else {
//       return rejectWithValue(error.message);
//     }
//   }
// });



// export const edit_category = createAsyncThunk(
//     'user/edit_category',
//     async ({ id, taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId }, { rejectWithValue }) => {
//         console.log(id, taskNameAr, taskNameEn, descriptionEn, descriptionAr, singleImage, parentId);

//         try {
//             // تجهيز الهيدر الخاص بالطلب
//             const config = {
//                 headers: {
//                     'Authorization': `Bearer ${token}`, // التوكن لو كان مطلوباً
//                     'Content-Type': 'multipart/form-data' // التأكد من أن الهيدر مناسب لإرسال ملفات
//                 },
//             };

//             // تجهيز البيانات باستخدام FormData
//             const formData = new FormData();
//             formData.append('name_ar', taskNameAr);
//             formData.append('name_en', taskNameEn);
//             formData.append('description_en', descriptionEn);
//             formData.append('description_ar', descriptionAr);
//             formData.append('image', singleImage); // التأكد من أن الحقل هو ملف
//             formData.append('parent_id', parentId);

//             // إرسال الطلب
//             const response = await axios.post(`https://api.ashtiri.com/api/category/${id}`, formData, config);
//             console.log({ data: response.data });
//             return { data: response.data, status: response.status };
//         } catch (error) {
//             // التعامل مع الأخطاء
//             if (error.response) {
//                 console.error("Server responded with:", error.response.data);
//                 return rejectWithValue(error.response.data);
//             } else {
//                 console.error("Error:", error.message);
//                 return rejectWithValue(error.message);
//             }
//         }
//     }
// );


export const edit_ad = createAsyncThunk('user/edit_ad', async (adData, { rejectWithValue }) => {
  console.log({title: adData.name, description: adData.description, category_id: adData.category_id, image_url: adData.singleImage, link: adData.link})
  console.log(adData)
  try {
    const config = {
      headers: {
        'Authorization': `Bearer ${token}`,  // التوكن لو كان مطلوباً
        'Content-Type': 'multipart/form-data'  // التأكد من أن الهيدر مناسب لإرسال ملفات
      },
    };

    const response = await axios.post(`${apiUrl}api/ad/${adData.id}`, {title: adData.name, description: adData.description, category_id: adData.category_id, image_url: adData.singleImage, link: adData.link}, config);
      // التحقق من حالة النجاح
      const success = response.status >= 200 && response.status < 300;

      return { data: response.data, status: response.status, success };
  } catch (error) {
      if (error.response) {
          return rejectWithValue({ ...error.response.data, success: false }); 
      } else {
          console.error("Error:", error.message);
          return rejectWithValue({ message: error.message, success: false }); 
      }
  }
});








export const post_product = createAsyncThunk('user/post_product', async (productData, { rejectWithValue }) => {
  console.log(productData)
  try {
      const config = {
          headers: {
              'Authorization': `Bearer ${token}`,  
              'Content-Type': 'multipart/form-data'  
          },
      };

      const response = await axios.post(`${apiUrl}api/product`, productData, config);
      console.log({data: response.data});

      // التحقق من حالة النجاح
      const success = response.status >= 200 && response.status < 300;

      return { data: response.data, status: response.status, success }; // نرجع success
  } catch (error) {
      if (error.response) {
          console.log("Server responded with:", error.response.data);
          return rejectWithValue({ ...error.response.data, success: false }); // نرجع success: false
      } else {
          console.error("Error:", error.message);
          return rejectWithValue({ message: error.message, success: false }); // نرجع success: false
      }
  }
});

export const edit_product = createAsyncThunk('user/edit_product', async ({ productData, id }, { rejectWithValue }) => {
  try {
      const config = {
          headers: {
              'Authorization': `Bearer ${token}`,  
              'Content-Type': 'multipart/form-data'  
          },
      };

      const response = await axios.post(`${apiUrl}api/product/${id}`, productData, config);
      console.log({data: response.data});

      // التحقق من حالة النجاح
      const success = response.status >= 200 && response.status < 300;

      return { data: response.data, status: response.status, success }; // نرجع success
  } catch (error) {
      if (error.response) {
        return rejectWithValue({ ...error.response.data, success: false }); // نرجع success: false
      } else {
        return rejectWithValue({ message: error.message, success: false }); // نرجع success: false
      }
  }
});







export const API = createSlice({
  name: 'Data',
  initialState: {

    userData: null,
    login: null,
    verificationData: null,
    sendReset: null,
    verifyReset: null,
    resetPassword: null,
    get_customers: null,
    favorite_products: null,
    cart_data: null,
    low_stock: null,
    searsh_product: null,
    customers_report: null,
    inventory_report: null,
    sales_report: null,
    user_distribution: null,
    address_distribution: null,
    shipped_orders_count: null,
    invoice: null,
    product_status: null,
    archive_status: null,
    update_status: null,
    create_discount: null,
    all_discount: null,
    delete_discount: null,

    display_brands: null,
    order_items: null,
    product: null,
    category: null,
    ads: null,
    transactions_data: null,
    payment_status: null,
    paymen_methods: null,
    all_vendors: null,
    vendor_status: null,
    vendor_details: null,
    update_vendor: null,
    delete_vendor: null,
    post_product: null,
    edit_product: null,
    vendor_products: null,
    vendor_sales_report: null,
    show_category: null,
    notifications: null,
    remove_product_coupon: null,
    remove_discount_product: null,



    orders_status: null,
    update_orders: null,
    add_coupon: null,
    create_coupon: null,
    all_coupon: null,
    add_discount: null,


    
    delete_coupon: null,
    delete_customer: null,
    delete_category: null,
    delete_brands: null,
    delete_product: null,
    delete_ad: null,


    post_ads: null,
    post_category: null,
    register_vendor: null,
    update_profile: null,
    searsh_order: null,



    visitors_stats: null,
    get_revenues: null,
    main_statistics: null,
    inventory_overview: null,



    edit_category: null,
    edit_ad: null,
    category_add_product: null,
    vendor_profie: null,
    



    loadingAddUser: false,
    loadingVerification: false,
    loadingLogin:false,
    loadingSendReset: false,
    loadingVerifyReset: false,
    loadingresetPassword: false,
    loadingGet_customers: false,
    loadingFavorite_products: false,
    loadingCart_data: false,
    loadingLow_stock: false,
    loadingSearsh_product: false,
    loadingSearsh_order: false,
    loadingCustomers_report: false,
    loadingInventory_report: false,
    loadingSales_report: false,
    loadingUser_distribution: false,
    loadingShipped_orders_count: false,
    loadingAddress_distribution: false,
    loadingInvoice: false,
    loadingProduct_status: false,
    loadingArchive_status: false,
    loadingUpdate_status: false,
    loadingVendor_sales_report: false,
    loadingVendor_profie: false,
    loadingRemove_product_coupon: false,
    loadingRemove_discount_product: false,

    loadingDisplay_brands: false,
    loadingOrder_items: false,
    loadingProduct: false,
    loadingCategory: false,
    loadingAds: false,
    loadingTransactions_data: false,
    loadingPayment_status: false,
    loadingPaymen_methods: false,
    loadingAll_vendors: false,
    loadingVendor_status: false,
    loadingVendor_details: false,
    loadingUpdate_vendor: false,
    loadingCreate_discount: false,
    loadingAll_discount: false,
    loadingDelete_discount: false,
    loadingVendor_products: false,
    loadingShow_category: false,



    loadingOrders_status: false,
    loadingUpdate_orders: false,
    loadingAdd_coupon: false,
    loadingCreate_coupon: false,
    loadingAll_coupon: false,


    loadingDelete_coupon: false,
    loadingDelete_customer: false,
    loadingDelete_category: false,
    loadingDelete_brands: false,
    loadingDelete_product: false,
    loadingDelete_ad: false,
    loadingDelete_vendor: false,
    loadingPost_product: false,
    loadingEdit_product: false,
    loadingCategory_add_product: false,



    loadingPost_ads: false,
    loadingPost_category: false,
    loadingRegister_vendor: false,
    loadingUpdate_profile: false,


    
    loadingVisitors_stats: false,
    loadingGet_revenues: false,
    loadingMain_statistics: false,
    loadingInventory_overview: false,
    loadingAdd_discount: false,
    loadingNotifications: false,




    loadingEdit_category: false,
    loadingEdit_ad: false,





    errorAddUser: null,
    errorVerification: null,
    errorLogin: null,
    errorSendReset: null,
    errorVerifyReset: null,
    errorresetPassword: null,
    errorGet_customers: null,
    errorFavorite_products: null,
    errorCart_data: null,
    errorLow_stock: null,
    errorSearsh_product: null,
    errorCustomers_report: null,
    errorInventory_report: null,
    errorSales_report: null,
    errorUser_distribution: null,
    errorShipped_orders_count: null,
    errorAddress_distribution: null,
    errorInvoice: null,
    errorCreate_discount: null,



    errorDisplay_brands: null,
    errorOrder_items: null,
    errorProduct: null,
    errorCategory: null,
    errorAds: null,
    errorTransactions_data: null,
    errorPayment_status: null,
    errorPaymen_methods: null,
    errorAll_vendors: null,
    errorVendor_status: null,
    errorVendor_details: null,
    errorUpdate_vendor: null,


    

    errorOrders_status: null,
    errorUpdate_orders: null,
    errorAdd_coupon: null,
    errorCreate_coupon: null,
    errorSearsh_order: null,


    errorDelete_customer: null,
    errorPost_ads: null,
    errorPost_category: null,
    errorRegister_vendor: null,



    errorVisitors_stats: null,
    errorGet_revenues: null,
    errorMain_statistics: null,

    
    errorEdit_category: null,
    errorEdit_ad: null,

  },
  
  reducers: {
    addNumber: (state, action) => {
      state.number = action.payload.number;
    },
  },
  extraReducers: (builder) => {

    builder.addCase(addUser.pending, (state) => {
      state.loadingAddUser = true;
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.userData = action.payload;
      state.loadingAddUser = false;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.errorAddUser = action.payload;
      state.loadingAddUser = false;
    });

    builder.addCase(login.pending, (state) => {
      state.loadingLogin = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.login = action.payload;
      state.loadingLogin = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.errorLogin = action.payload;
      state.loadingLogin = false;
    });

    builder.addCase(verification.pending, (state) => {
      state.loadingVerification = true;
    });
    builder.addCase(verification.fulfilled, (state, action) => {
      state.verificationData = action.payload;
      state.loadingVerification = false;
    });
    builder.addCase(verification.rejected, (state, action) => {
      state.errorVerification = action.payload;
      state.loadingVerification = false;
    });

    builder.addCase(sendReset.pending, (state) => {
      state.loadingSendReset = true;
    });
    builder.addCase(sendReset.fulfilled, (state, action) => {
      state.sendReset = action.payload;
      state.loadingSendReset = false;
    });
    builder.addCase(sendReset.rejected, (state, action) => {
      state.errorSendReset = action.payload;
      state.loadingSendReset = false;
    });

    // verifyReset
    builder.addCase(verifyReset.pending, (state) => {
      state.loadingVerifyReset = true;
    });
    builder.addCase(verifyReset.fulfilled, (state, action) => {
      state.verifyReset = action.payload;
      state.loadingVerifyReset = false;
    });
    builder.addCase(verifyReset.rejected, (state, action) => {
      state.errorVerifyReset = action.payload;
      state.loadingVerifyReset = false;
    });

    // resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.loadingresetPassword = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPassword = action.payload;
      state.loadingresetPassword = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errorresetPassword = action.payload;
      state.loadingresetPassword = false;
    });


    // get_customers
    builder.addCase(get_customers.pending, (state) => {
      state.loadingGet_customers = true;
    });
    builder.addCase(get_customers.fulfilled, (state, action) => {
      state.get_customers = action.payload;
      state.loadingGet_customers = false;
    });
    builder.addCase(get_customers.rejected, (state, action) => {
      state.errorGet_customers = action.payload;
      state.loadingGet_customers = false;
    });


    // favorite_products
    builder.addCase(favorite_products.pending, (state) => {
      state.loadingFavorite_products = true;
    });
    builder.addCase(favorite_products.fulfilled, (state, action) => {
      state.favorite_products = action.payload;
      state.loadingFavorite_products = false;
    });
    builder.addCase(favorite_products.rejected, (state, action) => {
      state.errorFavorite_products = action.payload;
      state.loadingFavorite_products = false;
    });

    // cart_data
    builder.addCase(cart_data.pending, (state) => {
      state.loadingCart_data = true;
    });
    builder.addCase(cart_data.fulfilled, (state, action) => {
      state.cart_data = action.payload;
      state.loadingCart_data = false;
    });
    builder.addCase(cart_data.rejected, (state, action) => {
      state.errorCart_data = action.payload;
      state.loadingCart_data = false;
    });

    // low_stock
    builder.addCase(low_stock.pending, (state) => {
      state.loadingLow_stock = true;
    });
    builder.addCase(low_stock.fulfilled, (state, action) => {
      state.low_stock = action.payload;
      state.loadingLow_stock = false;
    });
    builder.addCase(low_stock.rejected, (state, action) => {
      state.errorLow_stock = action.payload;
      state.loadingLow_stock = false;
    });


    // searsh_product
    builder.addCase(searsh_product.pending, (state) => {
      state.loadingSearsh_product = true;
    });
    builder.addCase(searsh_product.fulfilled, (state, action) => {
      state.searsh_product = action.payload;
      state.loadingSearsh_product = false;
    });
    builder.addCase(searsh_product.rejected, (state, action) => {
      state.errorSearsh_product = action.payload;
      state.loadingSearsh_product = false;
    });


    // customers_report
    builder.addCase(customers_report.pending, (state) => {
      state.loadingCustomers_report = true;
    });
    builder.addCase(customers_report.fulfilled, (state, action) => {
      state.customers_report = action.payload;
      state.loadingCustomers_report = false;
    });
    builder.addCase(customers_report.rejected, (state, action) => {
      state.errorCustomers_report = action.payload;
      state.loadingCustomers_report = false;
    });

    // inventory_report
    builder.addCase(inventory_report.pending, (state) => {
      state.loadingInventory_report = true;
    });
    builder.addCase(inventory_report.fulfilled, (state, action) => {
      state.inventory_report = action.payload;
      state.loadingInventory_report = false;
    });
    builder.addCase(inventory_report.rejected, (state, action) => {
      state.errorInventory_report = action.payload;
      state.loadingCustomers_report = false;
    });

    // sales_report
    builder.addCase(sales_report.pending, (state) => {
      state.loadingSales_report = true;
    });
    builder.addCase(sales_report.fulfilled, (state, action) => {
      state.sales_report = action.payload;
      state.loadingSales_report = false;
    });
    builder.addCase(sales_report.rejected, (state, action) => {
      state.errorSales_report = action.payload;
      state.loadingSales_report = false;
    });

    // user_distribution
    builder.addCase(user_distribution.pending, (state) => {
      state.loadingUser_distribution = true;
    });
    builder.addCase(user_distribution.fulfilled, (state, action) => {
      state.user_distribution = action.payload;
      state.loadingUser_distribution = false;
    });
    builder.addCase(user_distribution.rejected, (state, action) => {
      state.errorUser_distribution = action.payload;
      state.loadingUser_distribution = false;
    });

    // shipped_orders_count
    builder.addCase(shipped_orders_count.pending, (state) => {
      state.loadingShipped_orders_count = true;
    });
    builder.addCase(shipped_orders_count.fulfilled, (state, action) => {
      state.shipped_orders_count = action.payload;
      state.loadingShipped_orders_count = false;
    });
    builder.addCase(shipped_orders_count.rejected, (state, action) => {
      state.errorShipped_orders_count = action.payload;
      state.loadingShipped_orders_count = false;
    });

    // address_distribution
    builder.addCase(address_distribution.pending, (state) => {
      state.loadingAddress_distribution = true;
    });
    builder.addCase(address_distribution.fulfilled, (state, action) => {
      state.address_distribution = action.payload;
      state.loadingAddress_distribution = false;
    });
    builder.addCase(address_distribution.rejected, (state, action) => {
      state.errorAddress_distribution = action.payload;
      state.loadingAddress_distribution = false;
    });

    // display_brands
    builder.addCase(display_brands.pending, (state) => {
      state.loadingDisplay_brands = true;
    });
    builder.addCase(display_brands.fulfilled, (state, action) => {
      state.display_brands = action.payload;
      state.loadingDisplay_brands = false;
    });
    builder.addCase(display_brands.rejected, (state, action) => {
      state.errorDisplay_brands = action.payload;
      state.loadingDisplay_brands = false;
    });

    // order_items
    builder.addCase(order_items.pending, (state) => {
      state.loadingOrder_items = true;
    });
    builder.addCase(order_items.fulfilled, (state, action) => {
      state.order_items = action.payload;
      state.loadingOrder_items = false;
    });
    builder.addCase(order_items.rejected, (state, action) => {
      state.errorOrder_items = action.payload;
      state.loadingOrder_items = false;
    });

  

    // product
    builder.addCase(product.pending, (state) => {
      state.loadingProduct = true;
    });
    builder.addCase(product.fulfilled, (state, action) => {
      state.product = action.payload;
      state.loadingProduct = false;
    });
    builder.addCase(product.rejected, (state, action) => {
      state.errorProduct = action.payload;
      state.loadingProduct = false;
    });

    // category
    builder.addCase(category.pending, (state) => {
      state.loadingCategory = true;
    });
    builder.addCase(category.fulfilled, (state, action) => {
      state.category = action.payload;
      state.loadingCategory = false;
    });
    builder.addCase(category.rejected, (state, action) => {
      state.errorCategory = action.payload;
      state.loadingCategory = false;
    });

  

    // ads
    builder.addCase(ads.pending, (state) => {
      state.loadingAds = true;
    });
    builder.addCase(ads.fulfilled, (state, action) => {
      state.ads = action.payload;
      state.loadingAds = false;
    });
    builder.addCase(ads.rejected, (state, action) => {
      state.errorAds = action.payload;
      state.loadingAds = false;
    });

    // transactions_data
    builder.addCase(transactions_data.pending, (state) => {
      state.loadingTransactions_data = true;
    });
    builder.addCase(transactions_data.fulfilled, (state, action) => {
      state.transactions_data = action.payload;
      state.loadingTransactions_data = false;
    });
    builder.addCase(transactions_data.rejected, (state, action) => {
      state.errorTransactions_data = action.payload;
      state.loadingTransactions_data = false;
    });

    // payment_status
    builder.addCase(payment_status.pending, (state) => {
      state.loadingPayment_status = true;
    });
    builder.addCase(payment_status.fulfilled, (state, action) => {
      state.payment_status = action.payload;
      state.loadingPayment_status = false;
    });
    builder.addCase(payment_status.rejected, (state, action) => {
      state.errorPayment_status = action.payload;
      state.loadingPayment_status = false;
    });

    // paymen_methods
    builder.addCase(paymen_methods.pending, (state) => {
      state.loadingPaymen_methods = true;
    });
    builder.addCase(paymen_methods.fulfilled, (state, action) => {
      state.paymen_methods = action.payload;
      state.loadingPaymen_methods = false;
    });
    builder.addCase(paymen_methods.rejected, (state, action) => {
      state.errorPaymen_methods = action.payload;
      state.loadingPaymen_methods = false;
    });

    // paymen_methods
    builder.addCase(all_vendors.pending, (state) => {
      state.loadingAll_vendors = true;
    });
    builder.addCase(all_vendors.fulfilled, (state, action) => {
      state.all_vendors = action.payload;
      state.loadingAll_vendors = false;
    });
    builder.addCase(all_vendors.rejected, (state, action) => {
      state.errorAll_vendors = action.payload;
      state.loadingAll_vendors = false;
    });

  
    // vendor_status
    builder.addCase(vendor_status.pending, (state) => {
      state.loadingVendor_status = true;
    });
    builder.addCase(vendor_status.fulfilled, (state, action) => {
      state.vendor_status = action.payload;
      state.loadingVendor_status = false;
    });
    builder.addCase(vendor_status.rejected, (state, action) => {
      state.errorVendor_status = action.payload;
      state.loadingVendor_status = false;
    });
  
    // vendor_status
    builder.addCase(vendor_details.pending, (state) => {
      state.loadingVendor_details = true;
    });
    builder.addCase(vendor_details.fulfilled, (state, action) => {
      state.vendor_details = action.payload;
      state.loadingVendor_details = false;
    });
    builder.addCase(vendor_details.rejected, (state, action) => {
      state.errorVendor_details = action.payload;
      state.loadingVendor_details = false;
    });

    // vendor_status
    builder.addCase(update_vendor.pending, (state) => {
      state.loadingUpdate_vendor = true;
    });
    builder.addCase(update_vendor.fulfilled, (state, action) => {
      state.update_vendor = action.payload;
      state.loadingUpdate_vendor = false;
    });
    builder.addCase(update_vendor.rejected, (state, action) => {
      state.errorUpdate_vendor = action.payload;
      state.loadingUpdate_vendor = false;
    });

    // orders_status
    builder.addCase(orders_status.pending, (state) => {
      state.loadingOrders_status = true;
    });
    builder.addCase(orders_status.fulfilled, (state, action) => {
      state.orders_status = action.payload;
      state.loadingOrders_status = false;
    });
    builder.addCase(orders_status.rejected, (state, action) => {
      state.errorOrders_status = action.payload;
      state.loadingOrders_status = false;
    });

    // update_orders
    builder.addCase(update_orders.pending, (state) => {
      state.loadingUpdate_orders = true;
    });
    builder.addCase(update_orders.fulfilled, (state, action) => {
      state.update_orders = action.payload;
      state.loadingUpdate_orders = false;
    });
    builder.addCase(update_orders.rejected, (state, action) => {
      state.errorUpdate_orders = action.payload;
      state.loadingUpdate_orders = false;
    });

    // add_coupon
    builder.addCase(add_coupon.pending, (state) => {
      state.loadingAdd_coupon = true;
    });
    builder.addCase(add_coupon.fulfilled, (state, action) => {
      state.add_coupon = action.payload;
      state.loadingAdd_coupon = false;
    });
    builder.addCase(add_coupon.rejected, (state, action) => {
      state.errorAdd_coupon = action.payload;
      state.loadingAdd_coupon = false;
    });

    // create_coupon
    builder.addCase(create_coupon.pending, (state) => {
      state.loadingCreate_coupon = true;
    });
    builder.addCase(create_coupon.fulfilled, (state, action) => {
      state.create_coupon = action.payload;
      state.loadingCreate_coupon = false;
    });
    builder.addCase(create_coupon.rejected, (state, action) => {
      state.errorCreate_coupon = action.payload;
      state.loadingCreate_coupon = false;
    });

    // all_coupon
    builder.addCase(all_coupon.pending, (state) => {
      state.loadingAll_coupon = true;
    });
    builder.addCase(all_coupon.fulfilled, (state, action) => {
      state.all_coupon = action.payload;
      state.loadingAll_coupon = false;
    });
    builder.addCase(all_coupon.rejected, (state, action) => {
      state.errorCreate_coupon = action.payload;
      state.loadingAll_coupon = false;
    });

    // add_coupon
    builder.addCase(delete_customer.pending, (state) => {
      state.loadingDelete_customer = true;
    });
    builder.addCase(delete_customer.fulfilled, (state, action) => {
      state.delete_customer = action.payload;
      state.loadingDelete_customer = false;
    });
    builder.addCase(delete_customer.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_customer = false;
    });

    // delete_category
    builder.addCase(delete_category.pending, (state) => {
      state.loadingDelete_category = true;
    });
    builder.addCase(delete_category.fulfilled, (state, action) => {
      state.delete_category = action.payload;
      state.loadingDelete_category = false;
    });
    builder.addCase(delete_category.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_category = false;
    });

    // delete_category
    builder.addCase(delete_brands.pending, (state) => {
      state.loadingDelete_brands = true;
    });
    builder.addCase(delete_brands.fulfilled, (state, action) => {
      state.delete_brands = action.payload;
      state.loadingDelete_brands = false;
    });
    builder.addCase(delete_brands.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_brands = false;
    });

  

    // delete_product
    builder.addCase(delete_product.pending, (state) => {
      state.loadingDelete_product = true;
    });
    builder.addCase(delete_product.fulfilled, (state, action) => {
      state.delete_product = action.payload;
      state.loadingDelete_product = false;
    });
    builder.addCase(delete_product.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_product = false;
    });

    // delete_ad
    builder.addCase(delete_ad.pending, (state) => {
      state.loadingDelete_ad = true;
    });
    builder.addCase(delete_ad.fulfilled, (state, action) => {
      state.delete_ad = action.payload;
      state.loadingDelete_ad = false;
    });
    builder.addCase(delete_ad.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_ad = false;
    });

    // delete_coupon
    builder.addCase(delete_coupon.pending, (state) => {
      state.loadingDelete_coupon = true;
    });
    builder.addCase(delete_coupon.fulfilled, (state, action) => {
      state.delete_coupon = action.payload;
      state.loadingDelete_coupon = false;
    });
    builder.addCase(delete_coupon.rejected, (state, action) => {
      state.errorDelete_customer = action.payload;
      state.loadingDelete_coupon = false;
    });

    // post_ads
    builder.addCase(post_ads.pending, (state) => {
      state.loadingPost_ads = true;
    });
    builder.addCase(post_ads.fulfilled, (state, action) => {
      state.post_ads = action.payload;
      state.loadingPost_ads = false;
    });
    builder.addCase(post_ads.rejected, (state, action) => {
      state.errorPost_ads = action.payload;
      state.loadingPost_ads = false;
    });

    // post_category
    builder.addCase(post_category.pending, (state) => {
      state.loadingPost_category = true;
    });
    builder.addCase(post_category.fulfilled, (state, action) => {
      state.post_category = action.payload;
      state.loadingPost_category = false;
    });
    builder.addCase(post_category.rejected, (state, action) => {
      state.errorPost_category = action.payload;
      state.loadingPost_category = false;
    });

  
    // invoice
    builder.addCase(invoice.pending, (state) => {
      state.loadingInvoice = true;
    });
    builder.addCase(invoice.fulfilled, (state, action) => {
      state.invoice = action.payload;
      state.loadingInvoice = false;
    });
    builder.addCase(invoice.rejected, (state, action) => {
      state.errorInvoice = action.payload;
      state.loadingInvoice = false;
    });
  
    // product_status
    builder.addCase(product_status.pending, (state) => {
      state.loadingProduct_status = true;
    });
    builder.addCase(product_status.fulfilled, (state, action) => {
      state.product_status = action.payload;
      state.loadingProduct_status = false;
    });
    builder.addCase(product_status.rejected, (state, action) => {
      state.errorInvoice = action.payload;
      state.loadingProduct_status = false;
    });

  
  
    // archive_status
    builder.addCase(archive_status.pending, (state) => {
      state.loadingArchive_status = true;
    });
    builder.addCase(archive_status.fulfilled, (state, action) => {
      state.archive_status = action.payload;
      state.loadingArchive_status = false;
    });
    builder.addCase(archive_status.rejected, (state, action) => {
      state.errorInvoice = action.payload;
      state.loadingArchive_status = false;
    });

    // update_status
    builder.addCase(update_status.pending, (state) => {
      state.loadingUpdate_status = true;
    });
    builder.addCase(update_status.fulfilled, (state, action) => {
      state.update_status = action.payload;
      state.loadingUpdate_status = false;
    });
    builder.addCase(update_status.rejected, (state, action) => {
      state.errorInvoice = action.payload;
      state.loadingUpdate_status = false;
    });

  
    // Visitors_stats
    builder.addCase(visitors_stats.pending, (state) => {
      state.loadingVisitors_stats = true;
    });
    builder.addCase(visitors_stats.fulfilled, (state, action) => {
      state.visitors_stats = action.payload;
      state.loadingVisitors_stats = false;
    });
    builder.addCase(visitors_stats.rejected, (state, action) => {
      state.errorVisitors_stats = action.payload;
      state.loadingVisitors_stats = false;
    });

    // Visitors_stats
    builder.addCase(get_revenues.pending, (state) => {
      state.loadingGet_revenues = true;
    });
    builder.addCase(get_revenues.fulfilled, (state, action) => {
      state.get_revenues = action.payload;
      state.loadingGet_revenues = false;
    });
    builder.addCase(get_revenues.rejected, (state, action) => {
      state.errorGet_revenues = action.payload;
      state.loadingGet_revenues = false;
    });

  
    // main_statistics
    builder.addCase(main_statistics.pending, (state) => {
      state.loadingMain_statistics = true;
    });
    builder.addCase(main_statistics.fulfilled, (state, action) => {
      state.main_statistics = action.payload;
      state.loadingMain_statistics = false;
    });
    builder.addCase(main_statistics.rejected, (state, action) => {
      state.errorMain_statistics = action.payload;
      state.loadingMain_statistics = false;
    });

    // inventory_overview
    builder.addCase(inventory_overview.pending, (state) => {
      state.loadingInventory_overview = true;
    });
    builder.addCase(inventory_overview.fulfilled, (state, action) => {
      state.inventory_overview = action.payload;
      state.loadingInventory_overview = false;
    });
    builder.addCase(inventory_overview.rejected, (state, action) => {
      state.errorMain_statistics = action.payload;
      state.loadingInventory_overview = false;
    });

    // register_vendor
    builder.addCase(register_vendor.pending, (state) => {
      state.loadingRegister_vendor = true;
    });
    builder.addCase(register_vendor.fulfilled, (state, action) => {
      state.register_vendor = action.payload;
      state.loadingRegister_vendor = false;
    });
    builder.addCase(register_vendor.rejected, (state, action) => {
      state.errorRegister_vendor = action.payload;
      state.loadingRegister_vendor = false;
    });

    // register_vendor
    builder.addCase(edit_category.pending, (state) => {
      state.loadingEdit_category = true;
    });
    builder.addCase(edit_category.fulfilled, (state, action) => {
      state.edit_category = action.payload;
      state.loadingEdit_category = false;
    });
    builder.addCase(edit_category.rejected, (state, action) => {
      state.errorEdit_category = action.payload;
      state.loadingEdit_category = false;
    });

    // edit_ad
    builder.addCase(edit_ad.pending, (state) => {
      state.loadingEdit_ad = true;
    });
    builder.addCase(edit_ad.fulfilled, (state, action) => {
      state.edit_ad = action.payload;
      state.loadingEdit_ad = false;
    });
    builder.addCase(edit_ad.rejected, (state, action) => {
      state.errorEdit_ad = action.payload;
      state.loadingEdit_ad = false;
    });
    // create_discount
    builder.addCase(create_discount.pending, (state) => {
      state.loadingCreate_discount = true;
    });
    builder.addCase(create_discount.fulfilled, (state, action) => {
      state.create_discount = action.payload;
      state.loadingCreate_discount = false;
    });
    builder.addCase(create_discount.rejected, (state, action) => {
      state.errorCreate_discount = action.payload;
      state.loadingCreate_discount = false;
    });

    // create_discount
    builder.addCase(all_discount.pending, (state) => {
      state.loadingAll_discount = true;
    });
    builder.addCase(all_discount.fulfilled, (state, action) => {
      state.all_discount = action.payload;
      state.loadingAll_discount = false;
    });
    builder.addCase(all_discount.rejected, (state, action) => {
      state.errorCreate_discount = action.payload;
      state.loadingAll_discount = false;
    });

    // delete_discount
    builder.addCase(delete_discount.pending, (state) => {
      state.loadingDelete_discount = true;
    });
    builder.addCase(delete_discount.fulfilled, (state, action) => {
      state.delete_discount = action.payload;
      state.loadingDelete_discount = false;
    });
    builder.addCase(delete_discount.rejected, (state, action) => {
      state.errorDelete_discount = action.payload;
      state.loadingDelete_discount = false;
    });

    // delete_vendor
    builder.addCase(delete_vendor.pending, (state) => {
      state.loadingDelete_vendor = true;
    });
    builder.addCase(delete_vendor.fulfilled, (state, action) => {
      state.post_product = action.payload;
      state.loadingDelete_vendor = false;
    });
    builder.addCase(delete_vendor.rejected, (state, action) => {
      state.errorDelete_discount = action.payload;
      state.loadingDelete_vendor = false;
    });
    // post_product
    builder.addCase(post_product.pending, (state) => {
      state.loadingPost_product = true;
    });
    builder.addCase(post_product.fulfilled, (state, action) => {
      state.post_product = action.payload;
      state.loadingPost_product = false;
    });
    builder.addCase(post_product.rejected, (state, action) => {
      state.errorDelete_discount = action.payload;
      state.loadingPost_product = false;
    });

    // update_profile
    builder.addCase(update_profile.pending, (state) => {
      state.loadingUpdate_profile = true;
    });
    builder.addCase(update_profile.fulfilled, (state, action) => {
      state.update_profile = action.payload;
      state.loadingUpdate_profile = false;
    });
    builder.addCase(update_profile.rejected, (state, action) => {
      state.loadingUpdate_profile = false;
    });

    // add_discount
    builder.addCase(add_discount.pending, (state) => {
      state.loadingAdd_discount = true;
    });
    builder.addCase(add_discount.fulfilled, (state, action) => {
      state.add_discount = action.payload;
      state.loadingAdd_discount = false;
    });
    builder.addCase(add_discount.rejected, (state, action) => {
      state.loadingAdd_discount = false;
    });

    // add_discount
    builder.addCase(edit_product.pending, (state) => {
      state.loadingEdit_product = true;
    });
    builder.addCase(edit_product.fulfilled, (state, action) => {
      state.edit_product = action.payload;
      state.loadingEdit_product = false;
    });
    builder.addCase(edit_product.rejected, (state, action) => {
      state.loadingEdit_product = false;
    });

  
    // vendor_products
    builder.addCase(vendor_products.pending, (state) => {
      state.loadingVendor_products = true;
    });
    builder.addCase(vendor_products.fulfilled, (state, action) => {
      state.vendor_products = action.payload;
      state.loadingVendor_products = false;
    });
    builder.addCase(vendor_products.rejected, (state, action) => {
      state.loadingVendor_products = false;
    });

    // vendor_sales_report
    builder.addCase(vendor_sales_report.pending, (state) => {
      state.loadingVendor_sales_report = true;
    });
    builder.addCase(vendor_sales_report.fulfilled, (state, action) => {
      state.vendor_sales_report = action.payload;
      state.loadingVendor_sales_report = false;
    });
    builder.addCase(vendor_sales_report.rejected, (state, action) => {
      state.loadingVendor_sales_report = false;
    });

    // vendor_sales_report
    builder.addCase(show_category.pending, (state) => {
      state.loadingShow_category = true;
    });
    builder.addCase(show_category.fulfilled, (state, action) => {
      state.show_category = action.payload;
      state.loadingShow_category = false;
    });
    builder.addCase(show_category.rejected, (state, action) => {
      state.loadingShow_category = false;
    });

    // notifications
    builder.addCase(notifications.pending, (state) => {
      state.loadingNotifications = true;
    });
    builder.addCase(notifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
      state.loadingNotifications = false;
    });
    builder.addCase(notifications.rejected, (state, action) => {
      state.loadingNotifications = false;
    });
    // notifications
    builder.addCase(category_add_product.pending, (state) => {
      state.loadingCategory_add_product = true;
    });
    builder.addCase(category_add_product.fulfilled, (state, action) => {
      state.category_add_product = action.payload;
      state.loadingCategory_add_product = false;
    });
    builder.addCase(category_add_product.rejected, (state, action) => {
      state.loadingCategory_add_product = false;
    });

    
    // vendor_profie
    builder.addCase(vendor_profie.pending, (state) => {
      state.loadingVendor_profie = true;
    });
    builder.addCase(vendor_profie.fulfilled, (state, action) => {
      state.vendor_profie = action.payload;
      state.loadingVendor_profie = false;
    });
    builder.addCase(vendor_profie.rejected, (state, action) => {
      state.loadingVendor_profie = false;
    });

    // searsh_order
    builder.addCase(searsh_order.pending, (state) => {
      state.loadingSearsh_order = true;
    });
    builder.addCase(searsh_order.fulfilled, (state, action) => {
      state.searsh_order = action.payload;
      state.loadingSearsh_order = false;
    });
    builder.addCase(searsh_order.rejected, (state, action) => {
      state.errorSearsh_order = action.payload;
      state.loadingSearsh_order = false;
    });

    // remove_product_coupon
    builder.addCase(remove_product_coupon.pending, (state) => {
      state.loadingRemove_product_coupon = true;
    });
    builder.addCase(remove_product_coupon.fulfilled, (state, action) => {
      state.remove_product_coupon = action.payload;
      state.loadingRemove_product_coupon = false;
    });
    builder.addCase(remove_product_coupon.rejected, (state, action) => {
      state.errorSearsh_order = action.payload;
      state.loadingRemove_product_coupon = false;
    });

    // remove_discount_product
    builder.addCase(remove_discount_product.pending, (state) => {
      state.loadingRemove_discount_product = true;
    });
    builder.addCase(remove_discount_product.fulfilled, (state, action) => {
      state.remove_discount_product = action.payload;
      state.loadingRemove_discount_product = false;
    });
    builder.addCase(remove_discount_product.rejected, (state, action) => {
      state.errorSearsh_order = action.payload;
      state.loadingRemove_discount_product = false;
    });

    

  }
});

export const { addNumber } = API.actions;
export default API.reducer;


