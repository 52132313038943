import React from 'react';
import { Bar } from 'react-chartjs-2';

const AgeChart = () => {
    const customers = [
        { name: 'أحمد', gender: 'ذكر', age: 25, region: 'القاهرة' },
        { name: 'سارة', gender: 'أنثى', age: 30, region: 'الإسكندرية' },
        { name: 'محمد', gender: 'ذكر', age: 40, region: 'القاهرة' },
        { name: 'ليلى', gender: 'أنثى', age: 35, region: 'الإسكندرية' },
        { name: 'علي', gender: 'ذكر', age: 50, region: 'أسيوط' },
        { name: 'نور', gender: 'أنثى', age: 28, region: 'الإسكندرية' },
        { name: 'يوسف', gender: 'ذكر', age: 22, region: 'القاهرة' },
      ];
      const ageGroups = customers.reduce((acc, customer) => {
    const ageRange =
      customer.age < 20
        ? 'أقل من 20'
        : customer.age <= 30
        ? '20-30'
        : customer.age <= 40
        ? '30-40'
        : 'أكثر من 40';
    acc[ageRange] = (acc[ageRange] || 0) + 1;
    return acc;
  }, {});

  // إعداد البيانات للرسم البياني
  const data = {
    labels: Object.keys(ageGroups),
    datasets: [
      {
        label: 'العملاء حسب العمر',
        data: Object.values(ageGroups),
        backgroundColor: ['#FFCE56', '#36A2EB', '#FF6384', '#4BC0C0'],
      },
    ],
  };

  return (
    <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
      <Bar data={data} />
    </div>
  );
};

export default AgeChart;