import './ProductsList.css';
import './InventoryReports.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faChevronRight, faEdit, faListAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import img1 from './images/Asset 5.png';
import img2 from './images/IMG-20240723-WA0008.jpg';
import img3 from './images/bWseWtl0lxGx1c2Qn8u5kySbfXzViOKx970C0gci.jpg';
import img4 from './images/Asset 1.png';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { searsh_product, inventory_report, sales_report } from "./Redux/API";
import Loader from './Loader';
import { useEffect } from 'react';

function InventoryReports() {
  
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const searsh_products = useSelector(state => state.Data.searsh_product);
  const loadingSearsh_products = useSelector(state => state.Data.loadingSearsh_products);
  const errorSearsh_product = useSelector(state => state.Data.errorSearsh_product);

  
  // **حالة البحث**
  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
  };

  useEffect(() => {
      dispatch(searsh_product(searshBroduct)); // استدعاء البحث
  }, [searshBroduct, dispatch]);


  useEffect(() => {
    dispatch(inventory_report())
    dispatch(sales_report({start_date:'2023-01-01', end_date:'2024-9-31'}))
  }, [])


  const inventory_reports = useSelector(state => state.Data.inventory_report);
  const sales_reports = useSelector(state => state.Data.sales_report);
  const loadingInventory_report = useSelector(state => state.Data.loadingInventory_report);
console.log(inventory_reports)
  const listOrders = [
    t('image'),
    t('name'),
    t('category'),
    t('price'),
    t(' اجمالى الكميه '),
    t('العدد المباع '),
    t('العدد المتبقى'),
  ];




  return (
    <div className="ProductsList">
      <Header />
      <div className='container-fluid'>
        {loadingInventory_report && <Loader />}
        <div className='row align-items-center'>
          <div className='name_header d-flex'>
            <i className='font-size3'><FontAwesomeIcon icon={faListAlt} /></i>
            <p>{t('products_list.products_list')}</p>
          </div>
        </div>
{/* 
        <div className='container'>
          <div className='row mt-4'>
            <div className='sarsh col-5'>
            <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} placeholder={t('products_list.search')} />
            </div>
          </div>
        </div> */}

        <div className='container'>
          <div className='Orders'>

            <div className='title_color mt-2'>
            <h5>{t('best_selling_products')}</h5>
            <div className='Orders_border row justify-content-between'>
                    {listOrders.map((e, index) => (
                        <p className='col-1 text-center mb-3' key={index}>{e}</p>
                    ))}
                </div>
            </div>


            {inventory_reports?.data?.data?.top_selling_products.map((item) => (
              <div key={item.id}>
                <div className='Orders_border hover row align-items-center justify-content-between'>
                  <p className='col-1 text-center'>
                    <img src={item.main_image} alt={item.id}/>
                  </p>
                  <p className='col-1 text-center line'>{item.name}</p>
                  <p className='col-1 text-center'>{item.category}</p>
                  <p className='col-1 text-center'>{item.new_price}</p>
                  <p className='col-1 text-center'>{item.total_quantity}</p>
                  <p className='col-1 text-center'>{item.sold_quantity}</p>
                  <p className='col-1 text-center'>{item.remaining_quantity}</p>
                </div>
              </div>
            ))}
            <div className='title_color mt-2'>
            <h5>{t('featured_products')}</h5>
            <div className='Orders_border row justify-content-between'>
                    {listOrders.map((e, index) => (
                        <p className='col-1 text-center mb-3' key={index}>{e}</p>
                    ))}
                </div>
            </div>


            {inventory_reports?.data?.data?.products_out_of_stock.map((item) => (
              <div key={item.id}>
                <div className='Orders_border hover row align-items-center justify-content-between'>
                  <p className='col-1 text-center'>
                    <img src={item.main_image} alt={item.id}/>
                  </p>
                  <p className='col-1 text-center'>{item.name}</p>
                  <p className='col-1 text-center'>{item.category}</p>
                  <p className='col-1 text-center'>{item.new_price}</p>
                  <p className='col-1 text-center'>{item.total_quantity}</p>
                  <p className='col-1 text-center'>{item.sold_quantity}</p>
                  <p className='col-1 text-center'>{item.remaining_quantity}</p>
                </div>
              </div>
            ))}

            <div className='title_color mt-2'>
            <h5>{t('remaining_stock')}</h5>
            <div className='Orders_border row justify-content-between'>
                    {listOrders.map((e, index) => (
                        <p className='col-1 text-center mb-3' key={index}>{e}</p>
                    ))}
                </div>
            </div>



            {inventory_reports?.data?.data?.products_with_stock.map((item) => (
              <div key={item.id}>
                <div className='Orders_border hover row align-items-center justify-content-between'>
                  <p className='col-1 text-center'>
                    <img src={item.main_image} alt={item.id}/>
                  </p>
                  <p className='col-1 text-center'>{item.name}</p>
                  <p className='col-1 text-center'>{item.category}</p>
                  <p className='col-1 text-center'>{item.new_price}</p>
                  <p className='col-1 text-center'>{item.total_quantity}</p>
                  <p className='col-1 text-center'>{item.sold_quantity}</p>
                  <p className='col-1 text-center'>{item.remaining_quantity}</p>
                </div>
              </div>
            ))}


          </div>
        </div>
      </div>
    </div>
  );
}

export default InventoryReports;