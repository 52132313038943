import React, { useState, useCallback, useEffect } from "react";
import './AddCategoire.css';
import './EditCategory.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCartPlus, faCheck, faChevronRight, faCloudUpload } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { post_category, edit_category, show_category, category } from "./Redux/API";
import Loader from './Loader';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


function EditCategory() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadingShow_category = useSelector(state => state.Data.loadingShow_category);
    const show_categorys = useSelector(state => state.Data.show_category);
    // const Category = show_categorys?.data?.data;

    console.log(show_categorys?.data?.data)

  useEffect(() => {
    const id = localStorage.getItem("category_id");
    dispatch(show_category(id));
    dispatch(category())

    }, [dispatch]);

    const categoryList = useSelector(state => state.Data.category);
    const loadingPost_category = useSelector(state => state.Data.loadingPost_category);
    const loadingEdit_product = useSelector(state => state.Data.loadingEdit_product);

    const [taskNameAr, setTaskNameAr] = useState('');
    const [taskNameEn, setTaskNameEn] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [descriptionAr, setDescriptionAr] = useState('');
    const [singleImage, setSingleImage] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [activeCategory, setActiveCategory] = useState(null);
    const [showBox, setShowBox] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (show_categorys?.data?.data) {
            setTaskNameAr(show_categorys?.data?.data.name_ar || '');
            setTaskNameEn(show_categorys?.data?.data.name_en || '');
            setDescriptionAr(show_categorys?.data?.data.description_ar || '');
            setDescriptionEn(show_categorys?.data?.data.description_en || '');
            setSingleImage(show_categorys?.data?.data.image || null);
            setParentId(show_categorys?.data?.data.parent_id || null);

            const foundCategory = categoryList?.data?.data.find(cat => cat.id === show_categorys?.data?.data.id
            );
            setActiveCategory(foundCategory ? foundCategory.name : null);
        }
    }, [show_categorys?.data?.data, categoryList]);

    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSingleImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    const handleToggle = (id, name) => {
        setParentId(id);
        setActiveCategory(name);
        setShowBox(false);
    };
    

// عند الرجوع للخلف
const handleBack = () => {
    // const confirmBack = window.confirm('Are you sure you want to leave and clear the fields?');
    // if (confirmBack) {
        setTaskNameAr('');
        setTaskNameEn('');
        setDescriptionAr('');
        setDescriptionEn('');
        setSingleImage(null);
        setParentId(null);
        setActiveCategory(null);
        navigate(-1);
    // }
};

useEffect(() => {
    setTaskNameAr('');
    setTaskNameEn('');
    setDescriptionAr('');
    setDescriptionEn('');
    setSingleImage(null);
    setParentId(null);
    setActiveCategory(null);
  }, [loadingEdit_product])

   


    const uploadTask = (e) => {
        e.preventDefault();
    
        if (!taskNameAr || !taskNameEn || !descriptionEn || !descriptionAr) {
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
            return;
        }
    
        // إذا لم يتم رفع صورة جديدة
        const imageToSend = singleImage instanceof File ? singleImage : null;
    
        if (show_categorys?.data?.data) {
            dispatch(edit_category({
                id: show_categorys?.data?.data.id,
                taskNameAr,
                taskNameEn,
                descriptionAr,
                descriptionEn,
                singleImage: imageToSend,
                parentId
            })).then((action) => {
                if (action.payload && action.payload.success) {
                    setTaskNameAr('');
                    setTaskNameEn('');
                    setDescriptionAr('');
                    setDescriptionEn('');
                    setSingleImage(null);
                    setParentId(null);
                    setActiveCategory(null);
                }
            });
        }
    };

    return (
        <div className="AddCategoire">
            <Header />
            <div className="container">
                {loadingPost_category && <Loader />}
                {loadingShow_category && <Loader />}
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i><FontAwesomeIcon icon={faCartPlus} /></i>
                        <p>{t('addCategory.title')}</p>
                    </div>
                    <div className="container icon">
                    <i 
                        className="i" 
                        onClick={handleBack}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </i>
                    </div>
                </div>
                <form className="container row justify-content-around" onSubmit={uploadTask}>
                    <div className="col-6">
                        <div className="Add_img">
                            <p>{t('addCategory.singlePhoto')}</p>
                            <div className="add" {...getRootPropsSingle()}>
                                <input {...getInputPropsSingle()} />
                                {!singleImage && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addCategory.dragAndDropSingle')}</p>
                                    </div>
                                )}
                                {singleImage && (
                                    <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
                                )}
                            </div>
                        </div>

                        <div className="div">
                            <p>{t('addProduct.category')}</p>
                            <div onClick={() => setShowBox(!showBox)} className="slect_category">
                                <p>{activeCategory || 'Select Category'}</p>
                                <i><FontAwesomeIcon icon={faChevronRight} /></i>
                            </div>
                            {showBox && (
                                  <div>
                                  <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "150px", overflow: "hidden" }}>

                                  {categoryList?.data?.data.map((category) => (

                                      <div key={category.id} className="boxH">
                                          <div onClick={() => handleToggle(category.id, category.name)} className="oox_container">

                                              <div className="box_header">
                                                  <span>{category.name}</span>
                                                  {activeCategory === category.id && <span>✅</span>}
                                              </div>

                                          </div>
                                      </div>

                                  ))}
                                      </PerfectScrollbar>

                              </div>
                            )}
                        </div>
                    </div>

                    <div className="project_data col-5">
                        <div className="div1">
                            <label htmlFor='taskNameAr'>{t('name_ar')}</label>
                            <input type="text" id="taskNameAr" onChange={(e) => setTaskNameAr(e.target.value)} value={taskNameAr} />
                        </div>
                        <div className="div1">
                            <label htmlFor='taskNameEn'>{t('name_en')}</label>
                            <input type="text" id="taskNameEn" onChange={(e) => setTaskNameEn(e.target.value)} value={taskNameEn} />
                        </div>
                        <div className="div2">
                            <label htmlFor='descriptionAr'>{t('description_ar')}</label>
                            <input type="text" id="descriptionAr" onChange={(e) => setDescriptionAr(e.target.value)} value={descriptionAr} />
                        </div>
                        <div className="div2">
                            <label htmlFor='descriptionEn'>{t('description_en')}</label>
                            <input type="text" id="descriptionEn" onChange={(e) => setDescriptionEn(e.target.value)} value={descriptionEn} />
                        </div>

                    </div>
                    <button type="submit" className="m-4 col-6">
                    {t('edit_button')}
                        </button>
                </form>
            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addCategory.errorFields')}</div>

        </div>
    );
}

export default EditCategory;