
import React, { useState, useMemo, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { get_revenues } from './Redux/API';
import { useTranslation } from 'react-i18next';
import Loader from './Loader';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const RevenueComparisonChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const get_revenue = useSelector((state) => state?.Data?.get_revenues || {});
  const loadingGet_revenues = useSelector((state) => state?.Data?.loadingGet_revenues);

  const [selectedDate, setSelectedDate] = useState('');
  const [comparisonDate, setComparisonDate] = useState('');
  const [timeRange, setTimeRange] = useState('month');

  // ضبط التاريخ الافتراضي عند تحميل الشاشة
  useEffect(() => {
    if (!selectedDate) {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0]; // تنسيق YYYY-MM-DD
      setSelectedDate(formattedDate);
      setComparisonDate();
    }
  }, [selectedDate]);

  // جلب البيانات عند ضبط التاريخ الافتراضي
  useEffect(() => {
    if (selectedDate) {
      dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }));
    }
  }, [selectedDate, comparisonDate, dispatch]);

  const handleDateChange = (event) => setSelectedDate(event.target.value);
  const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);
  const handleTimeRangeChange = (range) => setTimeRange(range);

  const year = get_revenue?.data?.year;
  const comparisonYear = get_revenue?.data?.comparison_year;

  const extractData = (range, data) => {
    if (data && range === 'week') {
      return data.week;
    } else if (data && range === 'year') {
      return data.year;
    } else if (data && range === 'month') {
      return data.month;
    }
    return [];
  };

  const selectedData = useMemo(() => extractData(timeRange, year), [timeRange, year]);
  const comparisonData = useMemo(() => extractData(timeRange, comparisonYear), [timeRange, comparisonYear]);

  const chartConfig = {
    labels: (() => {
      if (timeRange === 'month') {
        // حساب عدد أيام الشهر بناءً على `selectedDate`
        const date = new Date(selectedDate || new Date());
        const year = date.getFullYear();
        const month = date.getMonth() + 1; // الأشهر تبدأ من 0
        const daysInMonth = new Date(year, month, 0).getDate(); // حساب عدد الأيام في الشهر
        return Array.from({ length: daysInMonth }, (_, i) => `Day ${i + 1}`); // إنشاء قائمة بالأيام
      }
  
      if (timeRange === 'week') {
        // عرض أسماء الأسابيع
        const weekLabels = ['week 1', 'week 2', 'week 3', 'week 4'];
        return weekLabels;
      }
  
      // عرض أسماء الشهور للسنة
      const yearLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return yearLabels;
    })(),
    datasets: [
      ...(selectedDate
        ? [
            {
              label: `${t('revenueChart.revenue')} ${selectedDate} - ${t(`revenueChart.${timeRange}`)}`,
              data: selectedData,
              fill: false,
              backgroundColor: '#3795BD',
              borderColor: '#3A1078',
            },
          ]
        : []),
      ...(comparisonDate
        ? [
            {
              label: `${t('revenueChart.revenue')} ${comparisonDate} - ${t(`revenueChart.${timeRange}`)}`,
              data: comparisonData,
              fill: false,
              backgroundColor: '#FFB22C',
              borderColor: '#FF4C4C',
            },
          ]
        : []),
    ],
  };

  return (
    <div className='row'>
      {loadingGet_revenues && <Loader />}
      <div className='col-6'>
        <label>{t('revenueChart.selectDate')}</label>
        <input
          className='w-100 m-0 mb-3'
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
        />
      </div>

      <div className='col-6'>
        <label>{t('revenueChart.comparisonDate')}</label>
        <input
          className='w-100 m-0 mb-3'
          type="date"
          value={comparisonDate}
          onChange={handleComparisonDateChange}
        />
      </div>

      <div className='m d-flex justify-content-between col-12'>
        <div className='col-4'>
          <button onClick={() => dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }))} className='btn btn-primary'>
            {t('revenueChart.displayData')}
          </button>
        </div>
        <div className='col-5'>
          <div className='btn-group mb-3'>
            {['week', 'month', 'year'].map((range) => (
              <button
                key={range}
                className={`btn ${timeRange === range ? 'btn-primary' : 'btn-outline-primary'}`}
                onClick={() => handleTimeRangeChange(range)}
              >
                {t(`revenueChart.${range}`)}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className='col-12'>
        <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
          {selectedData.length === 0 && comparisonData.length === 0 ? (
            <p style={{ textAlign: 'center', paddingTop: '20px' }}>{t('revenueChart.noData')}</p>
          ) : (
            <Line data={chartConfig} />
          )}
        </div>
      </div>
    </div>
  );
};

export default RevenueComparisonChart;
























// import React, { useState, useMemo } from 'react';
// import { Line } from 'react-chartjs-2';
// import { useDispatch, useSelector } from 'react-redux';
// import { get_revenues } from './Redux/API';
// import { useTranslation } from 'react-i18next';
// import Loader from './Loader';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend
// );

// const RevenueComparisonChart = () => {
//   const { t } = useTranslation();
//   const dispatch = useDispatch();

//   // بيانات الإيرادات من Redux
//   const get_revenue = useSelector((state) => state?.Data?.get_revenues || {});
//   const loadingGet_revenues = useSelector((state) => state?.Data?.loadingGet_revenues);

//   const [selectedDate, setSelectedDate] = useState('');
//   const [comparisonDate, setComparisonDate] = useState('');
//   const [timeRange, setTimeRange] = useState('day');

//   const handleDateChange = (event) => setSelectedDate(event.target.value);
//   const handleComparisonDateChange = (event) => setComparisonDate(event.target.value);

//   const handleSubmit = () => {
//     if (selectedDate && comparisonDate) {
//       dispatch(get_revenues({ start_date: selectedDate, comparison_date: comparisonDate }));
//     }
//   };

//   const handleTimeRangeChange = (range) => setTimeRange(range);


// const monthData = {
//   month: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
//   week: [50, 10, 52, 77],
//   year:[44,33,44,11,88,77,99,22,55,44,66,11,]
// }

//   // استخراج البيانات بناءً على العام والشهر والفترة الزمنية
//   const extractData = (range) => {
//     // const yearData = get_revenue?.data?.[year];
//     // const monthData = yearData?.data?.[month];
//     // console.log(monthData)
//     const year = {
//       month: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
//       week: [50, 10, 52, 77],
//       year:[44,33,44,11,88,77,99,22,55,44,66,11,]
//     }
//     const Comparison_year = {
//       month: [1,2,3,4,10,6,7,8,5,10,11,12,13,14,15,16,17,20,19,20,21,22,55,24,25,26,27,15,29,30],
//       week: [70, 15, 40, 77],
//       year:[44,33,88,11,88,22,99,77,55,44,33,11,]
//     }

//     if (year && range === 'week') {
//       return Object.values(year.week || 0);
//     } else if (year && range === 'year') {
//       return Object.values(year.year || 0);
//     } else if (year && range === 'month') {
//       return Object.values(year.month || 0);
//     }

//     return [];
//   };
//   // const extractData = (year, month, range) => {
//   //   const yearData = get_revenue?.data?.[year];
//   //   const monthData = yearData?.data?.[month];
//   //   console.log(monthData)

//   //   if (monthData && range === 'day') {
//   //     return Object.values(monthData?.day || 0);
//   //   } else if (monthData && range === 'week') {
//   //     return Object.values(monthData.week || 0);
//   //   } else if (yearData && range === 'month') {
//   //     return Array.from({ length: 12 }, (_, i) => yearData?.[`${i + 1}`]?.revenue || 0);
//   //   } else if (yearData && range === 'year') {
//   //     return [yearData?.year?.revenue || 0];
//   //   }

//   //   return [];
//   // };

//   const selectedYear = selectedDate.split('-')[0];
//   const selectedMonth = selectedDate.split('-')[1];
//   const comparisonYear = comparisonDate.split('-')[0];
//   const comparisonMonth = comparisonDate.split('-')[1];

//   const selectedData = useMemo(() => extractData(selectedYear, selectedMonth, timeRange), [selectedDate, timeRange, get_revenue]);
//   const comparisonData = useMemo(() => extractData(comparisonYear, comparisonMonth, timeRange), [comparisonDate, timeRange, get_revenue]);
//   // const selectedData = useMemo(() => extractData(selectedYear, selectedMonth, timeRange), [selectedDate, timeRange, get_revenue]);
//   // const comparisonData = useMemo(() => extractData(comparisonYear, comparisonMonth, timeRange), [comparisonDate, timeRange, get_revenue]);

//   // إعداد الرسم البياني
//   const chartConfig = {
//     labels: Array.from(
//       { length: Math.max(selectedData.length, comparisonData.length) },
//       (_, i) => {
//         if (timeRange === 'day') return `${i + 1}`; // الأيام
//         if (timeRange === 'week') {
//           const weekLabels = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//           return weekLabels[i % 7]; // عرض أيام الأسبوع
//         }
//         const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
//         return months[i % 12]; // الأشهر
//       }
//     ),
//     datasets: [
//       {
//         label: `${t('revenueChart.revenue')} ${selectedYear} (${selectedMonth}) - ${t(`revenueChart.${timeRange}`)}`,
//         data: selectedData,
//         fill: false,
//         backgroundColor: '#3795BD',
//         borderColor: '#3A1078',
//       },
//       {
//         label: `${t('revenueChart.revenue')} ${comparisonYear} (${comparisonMonth}) - ${t(`revenueChart.${timeRange}`)}`,
//         data: comparisonData,
//         fill: false,
//         backgroundColor: '#FFB22C',
//         borderColor: '#FF4C4C',
//       },
//     ],
//   };

//   return (
//     <div className='row'>
//       {loadingGet_revenues && <Loader />}
//       <div className='col-6'>
//         <label>{t('revenueChart.selectDate')}</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type="date"
//           value={selectedDate}
//           onChange={handleDateChange}
//         />
//       </div>

//       <div className='col-6'>
//         <label>{t('revenueChart.comparisonDate')}</label>
//         <input
//           className='w-100 m-0 mb-3'
//           type="date"
//           value={comparisonDate}
//           onChange={handleComparisonDateChange}
//         />
//       </div>

//       <div className='m d-flex justify-content-between col-12'>
//         <div className='col-4'>
//           <button onClick={handleSubmit} className='btn btn-primary'>
//             {t('revenueChart.displayData')}
//           </button>
//         </div>
//         <div className='col-5'>
//           <div className='btn-group mb-3'>
//             {/* {['day', 'week', 'month', 'year'].map((range) => ( */}
//             {['week', 'month', 'year'].map((range) => (
//               <button
//                 key={range}
//                 className={`btn ${timeRange === range ? 'btn-primary' : 'btn-outline-primary'}`}
//                 onClick={() => handleTimeRangeChange(range)}
//               >
//                 {t(`revenueChart.${range}`)}
//               </button>
//             ))}
//           </div>
//         </div>
//       </div>

//       <div className='col-12'>
//         <div style={{ border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
//           {selectedData.length === 0 && comparisonData.length === 0 ? (
//             <p style={{ textAlign: 'center', paddingTop: '20px' }}>{t('revenueChart.noData')}</p>
//           ) : (
//             <Line data={chartConfig} />
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RevenueComparisonChart;

