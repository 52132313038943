import { configureStore } from "@reduxjs/toolkit";
import HomeSlice from './HomeSlice'
import rootReducer  from "./modeReducer";
import API from "./API";

const enhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const store = configureStore({
    reducer: {
        products: HomeSlice,
        Data: API,
        rootReducer,
    }
})

export default store;

