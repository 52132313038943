import React, { useState, useEffect } from "react";
import './AddAllCoupon.css';
import './CreateDiscount.css';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { create_discount, all_discount, delete_discount } from "./Redux/API";
import Loader from './Loader';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CreateDiscount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadingCreate_discount = useSelector(state => state.Data.loadingCreate_discount);
  const loadingAll_discount = useSelector(state => state.Data.loadingAll_discount);
  const loadingDelete_discount = useSelector(state => state.Data.loadingDelete_discount);
  const all_discounts = useSelector(state => state.Data.all_discount);
  const errorCreate_discount = useSelector(state => state.Data.errorCreate_discount);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    dispatch(all_discount());
  }, [dispatch, loadingCreate_discount, loadingDelete_discount]);

  const [discountValue, setDiscountValue] = useState(null);
  const [code, setCode] = useState('');
  const [usageLimit, setUsageLimit] = useState("1");
  const [expiryDate, setExpiryDate] = useState("percentage");
  const [errors, setErrors] = useState("");

  const handleChangeDiscountValue = (e) => {
    const value = parseFloat(e.target.value);
    if (value >= 0 || isNaN(value)) {
      setDiscountValue(value);
    }
  };
  

  const handleChangeCode = (e) => {
    setCode(e.target.value);
  };

  const handleChangeExpiryDate = (e) => {
    setExpiryDate(e.target.value);
  };

  const validateForm = () => {
    return discountValue && code && usageLimit && expiryDate;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (validateForm()) {
      const formData = {
        discount_value: discountValue,
        code,
        usage_limit: usageLimit,
        expiry_date: expiryDate,
      };

      dispatch(create_discount(formData));

      setDiscountValue(null);
      setCode('');
      setUsageLimit("1");
      setExpiryDate("");
      setErrors("");
    } else {
      setErrors(t('errors.fill_all_fields'));
      setShowPopup(true)
      setTimeout(() => setShowPopup(false), 3000);
    }
  };

  const allCoupon = [
    t('coupon.name'), 
    t('coupon.discount_value'), 
    t('coupon.usage_limit'), 
    t('coupon.creation_date'), 
    t('coupon.action')
  ];

  const handleDeleteCoupon = (id) => {
    dispatch(delete_discount(id));
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleDateString('ar-EG', options);
  }
  
  return (
    <div className="AddAllCoupon">
      <Header />
      <div className='row align-items-center'>
        <div className='name_header d-flex'></div>
        <div className="container icon"></div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="container">
          {loadingDelete_discount && <Loader />}
          {loadingAll_discount && <Loader />}
          {loadingCreate_discount && <Loader />}
          <div className="row"> 
            <div className="Coupon">
              <label>{t('coupon.discount_value')}</label>
              <input
              type="number"
              value={discountValue}
              onChange={handleChangeDiscountValue}
              min="0" 
            />
            </div>
            <div className="Coupon">
              <label>{t('coupon.name')}</label>
              <input
                type="text"
                value={code}
                onChange={handleChangeCode}
              />
            </div>
            <div className="Coupon">
              <label>{t('coupon.discount_type')}</label>
              <input
                type="text"
                value={t('placeholder.percentage')}
                onChange={handleChangeExpiryDate}
                readOnly
              />
            </div>
          </div>
          <button className="button" type="submit">{t('actions.submit')}</button>

        </div>
      </form>

      {/* <div className={`popup ${errorCreate_discount?.message ? 'show' : ''}`}>{errorCreate_discount?.data?.data?.message}</div> */}
      <div className={`popup ${showPopup ? 'show' : ''}`}>{errors}</div>
      <div className='container'>
        <div className='Orders px-3 mt-5'>
          <div className='Orders_border row justify-content-around'>
            {allCoupon.map((e, index) => (
              <p className='col-2 text-center border-0' key={index}>{e}</p>
            ))}
          </div>

          {all_discounts?.data?.data?.discounts.map((order) => (
            <div key={order.id}>
              <div className='div_Transactions hover d-flex justify-content-around'>
                <p className='col-2 text-center'>{order.name}</p>
                <p className='col-2 text-center'>{order.discount_value}</p>
                <p className='col-2 text-center'>{order.is_active}</p>
                <p className='col-3 text-center'>{formatDate(order.created_at)}</p>
                <p className='col-2 text-center'>
                  <span 
                    className='span_icon2' 
                    onClick={() => handleDeleteCoupon(order.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </span>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateDiscount;