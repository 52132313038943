import React, { useState, useCallback } from "react";
import './AddProduct.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus, faCheck, faChevronRight, faCloudUpload, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { useDropzone } from 'react-dropzone';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { category, product_status, post_product,  edit_product} from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';
import { useTranslation } from "react-i18next";
import Header from "./Header/Header";
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';


function AddProduct() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const productD = useSelector(state => state?.products.productD);
    console.log(productD)
    useEffect(() => {
        if (productD) {
          setTitleEn(productD?.name_en || ''); 
          setTitleAr(productD?.name_ar || ''); 
          setDescriptionEn(productD?.description_en || ''); 
          setDescriptionAr(productD?.description_ar || ''); 
          setPrice(productD?.new_price || ''); 
          setQuantity(productD?.remaining_quantity || ''); 
          setPriceInput(productD?.delivery_price || '0');
          setSingleImage(productD?.main_image || []); 
          setMultipleImagesColor(productD?.colors || []);
          setMultipleImages(productD?.images || []);
          setTypeCategory(productD?.category || ''); 
          setTypeSubCategory(productD?.status || ''); 
          setSizes(productD?.sizes?.map(t => t.size) || []); 
          setCustomSizes([{ size: '' }]); 
          
          setMaterial(productD?.product_varients[0].material || ''); 
          setMaterialComposition(productD?.product_varients[0].material_composition || ''); 
          setWarrantyCare(productD?.product_varients[0].care_instructions || ''); 
          setGeneralSpecifications(productD?.product_varients[0].material_composition || ''); 
          setNatureComfort(productD?.product_varients[0].nature_comfort || ''); 
          setOccasion(productD?.product_varients[0].occasion || '');
          setModel(productD?.product_varients[0].model || ''); 
          setTimePriceInputs(
            productD.shippings?.map(shipping => ({ hours: shipping.key, price: shipping.value })) || 
            [{ hours: '', price: '' }]
          );
    
        }
      }, [productD]);



      const [multipleImagesColor, setMultipleImagesColor] = useState([]);
      
      const [multipleImages, setMultipleImages] = useState([]);

    const edit_products = useSelector(state => state.Data.edit_product);
    const loadingCategory = useSelector(state => state.Data.loadingCategory);
    const product_statu = useSelector(state => state.Data.product_status);
    const loadingPoduct_status = useSelector(state => state.Data.loadingProduct);
    const loadingEdit_product = useSelector(state => state.Data.loadingEdit_product);

    const post_products = useSelector(state => state.Data.post_product);
    const loadingPost_product = useSelector(state => state.Data.loadingPost_product);
    console.log(product_statu)

    useEffect(() => {
        dispatch(product_status()); 
    }, [dispatch]);

    const categorys = useSelector(state => state.Data.category);

    useEffect(() => {
      dispatch(category())
    }, [dispatch])

 const [titleEn, setTitleEn] = useState('');
  const [titleAr, setTitleAr] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionAr, setDescriptionAr] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [priceInput, setPriceInput] = useState('');
  const [timePriceInputs, setTimePriceInputs] = useState([{ hours: '', price: '' }]);
  const [singleImage, setSingleImage] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [customSizes, setCustomSizes] = useState([{ size: '' }]);
  const [typeCategory, setTypeCategory] = useState('');
  const [typeCategory_id, setTypeCategory_id] = useState(null);
  const [typeSubCategory, setTypeSubCategory] = useState('');

  const [material, setMaterial] = useState('');
  const [materialComposition, setMaterialComposition] = useState('');
  const [warrantyCare, setWarrantyCare] = useState('');
  const [generalSpecifications, setGeneralSpecifications] = useState('');
  const [natureComfort, setNatureComfort] = useState('');
  const [occasion, setOccasion] = useState('');
  const [model, setModel] = useState('');

  const [showSize, setShowSize] = useState(true);



    const [showPopup, setShowPopup] = useState(false);
    const [icon, setIcon] = useState(false);

    const onDropSingle = useCallback((acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            setSingleImage(acceptedFiles[0]);
        }
    }, []);

    const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({ onDrop: onDropSingle, multiple: false });

    const onDropMultiple = (acceptedFiles) => {
        const newImages = acceptedFiles.map(file => 
            Object.assign(file, {
                preview: URL.createObjectURL(file) 
            })
        );
    
        // استبدال الصور القديمة بالصور الجديدة
        setMultipleImages(newImages);
    };
    
    const { getRootProps: getRootPropsMultiple, getInputProps: getInputPropsMultiple } = useDropzone({ onDrop: onDropMultiple });


    const onDropMultipleColor = (acceptedFiles) => {
        const newColorImages = acceptedFiles.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file)
            })
        );
    
        setMultipleImagesColor(newColorImages);
    };
    
    const { getRootProps: getRootPropsMultipleColor, getInputProps: getInputPropsMultipleColor } = useDropzone({
        onDrop: onDropMultipleColor
    });

    const [showPutton, setShowPutton] = useState(true);


    const resetInputs = () => {
        setTitleEn('');
        setTitleAr('');
        setDescriptionEn('');
        setDescriptionAr('');
        setPrice('');
        setQuantity('');
        setPriceInput('');
        setTimePriceInputs([{ hours: '', price: '' }]); 
        setSingleImage([]);
        setMultipleImages([]);
        setMultipleImagesColor([]);
        setSizes([]); 
        setCustomSizes([{ size: '' }]); 
        setTypeCategory_id(''); 
        setTypeSubCategory(''); 
        setMaterial(''); 
        setMaterialComposition(''); 
        setWarrantyCare(''); 
        setGeneralSpecifications('');
        setNatureComfort(''); 
        setOccasion(''); 
        setModel(''); 
        setShowPutton(false)
      };
    
      function uploadTask(e) {
        e.preventDefault();
        let isError = false;
    
        if (
            titleEn.trim() === '' ||
            titleAr.trim() === '' ||
            descriptionEn.trim() === '' ||
            descriptionAr.trim() === '' ||
            price === '' ||
            quantity === '' ||
            priceInput.trim() === '' ||
            singleImage.length === 0 ||
            multipleImages.length === 0 || 
            sizes.length === 0 || 
            typeCategory_id === '' || 
            typeSubCategory.trim() === '' || 
            material.trim() === '' ||
            materialComposition.trim() === '' ||
            warrantyCare.trim() === '' ||
            generalSpecifications.trim() === '' ||
            natureComfort.trim() === '' ||
            occasion.trim() === '' ||
            model.trim() === ''
        ) {
            isError = true;
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    
        if (!isError) {
            const productData = {
                name_en: titleEn,
                name_ar: titleAr,
                description_ar: descriptionAr,
                description_en: descriptionEn,
                category_id: typeCategory_id,
                ad_id: null,
                supplier_id: null,
                status: typeSubCategory, 
                brand_id: null,
    
                main_img: singleImage,
                
                TimePrice: timePriceInputs,
                delivery_price: parseInt(priceInput),
    
                images: multipleImages,
                colors: multipleImagesColor,
                sizes: sizes, 
    
                variants: [
                    {
                        size: sizes[0], 
                        quantity: parseInt(quantity),
                        price: parseFloat(price),
                        material: material,
                        material_composition: materialComposition,
                        care_instructions: warrantyCare,
                        nature_comfort: natureComfort,
                        occasion: occasion,
                        model: model,
                        display_resolution: null,
                        battery_capacity: null,
                        storage_type: null,
                        ram: null,
                        camera_features: null
                    }
                ]
            };
            
            dispatch(post_product(productData)).then((action) => {
                if (action.payload && action.payload.success) {
                    console.log('Product added successfully!');
                    resetInputs(); 
                }
            });
            setShowPopup(false);
        }
    }
    const [showPopup_massge, setshowPopup_massge] = useState(false);
    
    function editProduct(e) {
        e.preventDefault();
        let isError = false;
    
        if (
            titleEn.trim() === '' ||
            titleAr.trim() === '' ||
            descriptionEn.trim() === '' ||
            descriptionAr.trim() === '' ||
            price === '' ||
            quantity === '' ||
            priceInput.trim() === '' ||
            singleImage.length === 0 || 
            multipleImages.length === 0 || 
            sizes.length === 0 || 
            typeCategory_id === '' || 
            typeSubCategory.trim() === '' ||
            material.trim() === '' ||
            materialComposition.trim() === '' ||
            warrantyCare.trim() === '' ||
            generalSpecifications.trim() === '' ||
            natureComfort.trim() === '' ||
            occasion.trim() === '' ||
            model.trim() === ''
        ) {
            isError = true;
            setShowPopup(true);
            setTimeout(() => setShowPopup(false), 3000);
        }
    
        if (!isError) {
            
            const mainImg = singleImage instanceof File ? singleImage : null;
            const images = multipleImages.every(img => img instanceof File) ? multipleImages : null;
            const ImagesColor = multipleImagesColor.every(img => img instanceof File) ? multipleImagesColor : null;
    
            const productData = {
                name_en: titleEn,
                name_ar: titleAr,
                description_ar: descriptionAr,
                description_en: descriptionEn,
                category_id: typeCategory_id,
                ad_id: null,
                supplier_id: null,
                status: typeSubCategory,
                brand_id: null,
                
                main_img: mainImg,
                
                TimePrice: timePriceInputs,
                delivery_price: parseInt(priceInput),
    
                images: images,
                colors: ImagesColor,
                sizes: sizes,
    
                variants: [
                    {
                        size: sizes[0],
                        quantity: parseInt(quantity),
                        price: parseFloat(price),
                        material: material,
                        material_composition: materialComposition,
                        care_instructions: warrantyCare,
                        nature_comfort: natureComfort,
                        occasion: occasion,
                        model: model,
                        display_resolution: null,
                        battery_capacity: null,
                        storage_type: null,
                        ram: null,
                        camera_features: null
                    }
                ]
            };
    
    
            // إرسال البيانات للتعديل
            dispatch(edit_product({ productData, id: productD.id })).then((action) => {
                if (action.payload && action.payload.success) {
                    resetInputs(); 
                    setshowPopup_massge(true); // عرض البوب أب
                    setTimeout(() => setshowPopup_massge(false), 4000); // إخفاء البوب أب بعد 3 ثواني
                }
            });
            setShowPopup(false);
        }
    }
    const allSizes = ["S", "M", "L", "XL", "XXL", "40", "41", "42", "43", "44"];

    // التعامل مع اختيار المقاسات الجاهزة
    const handleSizeChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setSizes((prev) => [...prev, value]);
        } else {
            setSizes((prev) => prev.filter((size) => size !== value));
        }
        setShowSize(false);

    };

    // إضافة حقل جديد للمقاسات المخصصة
    const addSizeField = () => {
        setCustomSizes([...customSizes, { size: "" }]);
    };

    // تحديث المقاس المكتوب في الحقول المخصصة
    const handleCustomSizeChange = (index, value) => {
        const updatedSizes = [...customSizes];
        updatedSizes[index].size = value;
        setCustomSizes(updatedSizes);
    };

    // إضافة المقاس المخصص إلى الـ sizes
    const addCustomSizeToList = (size) => {
        if (size && !sizes.includes(size)) {
            setSizes((prev) => [...prev, size]);
        }
    };

    const handlePriceChange = (event) => {
        setPriceInput(event.target.value);
    };

    // وظيفة لإضافة حقل جديد
    const addTimePriceField = () => {
        setTimePriceInputs([...timePriceInputs, { hours: "", price: "" }]);
    };

    // وظيفة لتحديث المدخلات
    const handleTimePriceChange = (index, field, value) => {
        const updatedInputs = [...timePriceInputs];
        updatedInputs[index][field] = value;
        setTimePriceInputs(updatedInputs);
    };

    const saveDeliveryDetails = () => {
        console.log(timePriceInputs); 
    };


    const[icon5, setIcon5] = useState(false)
    function handelClick5() {
      setIcon5(!icon5)
    }

    const[icon6, setIcon6] = useState(false)
    function handelClick6() {
      setIcon6(!icon6)
    }
  

    return (
        <div className="AddProduct">
            <Header />
            
           <div className="container">
            {(loadingPost_product || loadingEdit_product) && <Loader />}
                <div className='row align-items-center'>
                    <div className='name_header d-flex'>
                        <i className="font-size3"><FontAwesomeIcon icon={faPlusCircle} /></i>
                        <p>{t('addProduct.title')}</p>
                    </div>
                </div>
                <div className="container">
                    <form className="row justify-content-between mt-3">
                        <div className="col-7">
                            <div className="div1">

                            <label htmlFor="title">{t('nameProductEn')}</label>
                            <input type="text" id="title" onChange={(e) => setTitleEn(e.target.value)} value={titleEn} />
                            </div>
                            <div className="div1">
                            <label htmlFor="title">{t('nameProductAr')}</label>
                                <input type="text" id="title" onChange={(e) => setTitleAr(e.target.value)} value={titleAr} />
                            </div>

                            <div className="div2">
                                <label htmlFor='description'>{t('addProduct.productDescription')}(En)</label>
                                <input type="text" id="description" onChange={(e) => setDescriptionEn(e.target.value)} value={descriptionEn} />
                            </div>
                            <div className="div2">
                                <label htmlFor='description'>{t('addProduct.productDescription')}(Ar)</label>
                                <input type="text" id="description" onChange={(e) => setDescriptionAr(e.target.value)} value={descriptionAr} />
                            </div>

                            <div className="Add_img">
                                <p>{t('addCategory.singlePhoto')}</p>
                                <div className="add" {...getRootPropsSingle()}>
                                    <input {...getInputPropsSingle()} />
                                    {!singleImage && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addCategory.dragAndDropSingle')}</p>
                                        </div>
                                    )}
                                    {singleImage && (
                                        <img src={singleImage instanceof File ? URL.createObjectURL(singleImage) : singleImage} alt="Preview" className="img" />
                                    )}
                                </div>
                            </div>

                            <div className="Add_img">
                                <p>{t('addProduct.media')}</p>

                                <div className="add" {...getRootPropsMultiple()}>
                                <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "100%", overflow: "hidden" }}>
                                    <input {...getInputPropsMultiple()} />
                                    {!multipleImages?.length && (
                                        <div className="upload-placeholder">
                                            <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                            <p>{t('addProduct.dragAndDropMultiple')}</p>
                                        </div>
                                    )}
                                    <div className={`image-preview ${multipleImages?.length === 1 ? 'single' : 'multiple'} row`}>
                                        {multipleImages?.map((file, index) => (
                                            <img 
                                                key={index} 
                                                src={file instanceof File ? file.preview : file.image} 
                                                alt={index} 
                                                className="imgs col-4 mb-3" 
                                            />
                                        ))}
                                    </div>

                                    </PerfectScrollbar>

                                </div>
                            </div>

                            <div className="row justify-content-between mt-4">

                                <div className="div2 col-5">
                                    <label htmlFor='price'>{t('addProduct.price')}</label>
                                    <input type="number" id="price" onChange={(e) => setPrice(e.target.value)} value={price} />
                                </div>

                                <div className="div2 col-5">
                                    <label htmlFor='quantity'>{t('addProduct.quantity')}</label>
                                    <input type="number" id="quantity" onChange={(e) => setQuantity(e.target.value)} value={quantity} />
                                </div>

                                <div className="div2 mb-4 col-12">
        <label className="m-0">{t('add_delivery_cost')}</label>
        <input
          type="number"
          placeholder={t('enter_delivery_cost')}
          value={priceInput}
          onChange={handlePriceChange}
        />
      </div>

      <div className="delivery-price-selector">
        <div className="delivery-time-price-fields">
          <p>{t('add_hours_price')}</p>

          {timePriceInputs.map((input, index) => (
            <div key={index} className="input-group mb-4">
              <input
                type="text"
                placeholder={t('enter_hours')}
                value={input.hours}
                onChange={(e) => handleTimePriceChange(index, "hours", e.target.value)}
              />
              <input
                type="text"
                placeholder={t('enter_price')}
                value={input.price}
                onChange={(e) => handleTimePriceChange(index, "price", e.target.value)}
              />
            </div>
          ))}

          <div className="addTimePriceField">
            <p className="addTime" onClick={addTimePriceField}>{t('add_fields')}</p>
            <p className="addTime" onClick={saveDeliveryDetails}>{t('save_inputs')}</p>
          </div>
        </div>
      </div>

                            </div>
                        </div>
                        <div className="project_data col-5">
                        <div className="div">
    <p className="m-0">{t('addProduct.category')}</p>

    <div className="box_AddCompany div4 w-100">
        <div className="header" onClick={() => setIcon5(!icon5)}>
            <span>{typeCategory}</span>
            <i><FontAwesomeIcon icon={faChevronRight} /></i>
        </div>

        {icon5 && (
            <div className="cat category_container">
                <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "200px", overflow: "hidden" }}>
                    {categorys?.data?.data.map((category) => (
                        <div key={category.id} className="category-item">
                            <div className="category_content"
                            onClick={() => {
                                setTypeCategory(category.name);
                                setTypeCategory_id(category.id);
                                setIcon5(false);
                            }}
                            >
                            <img 
                                src={category.image || '/default-category.png'} 
                                alt={category.name} 
                                className="category-image"
                            />

                            <p 
                            >
                                {category.name}
                            </p>
                            </div>
                            {category.subcategories && category.subcategories.length > 0 && (
                                <div className="subcategory-list">
                                    {category.subcategories.map((subCategory) => (
                                    <div className="subcategory_content" 
                                    onClick={() => {
                                        setTypeCategory(subCategory.name);
                                        setTypeCategory_id(subCategory.id);
                                        setIcon5(false);
                                    }}
                                    >
                                    <img 
                                        src={subCategory.image || '/default-category.png'} 
                                        alt={subCategory.name} 
                                        className="category-image"
                                    />

                                    <p 
                                        style={{ fontWeight: "bold", cursor: "pointer" }}
                                    >
                                        {subCategory.name}
                                    </p>
                                    </div>

                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </PerfectScrollbar>
            </div>
        )}
    </div>
</div>
                        <div className="div mt-4">
                        <p className="m-0">{t('product_status')}</p>

                            <div className="box_AddCompany div4 w-100" onClick={handelClick6}>
                                <div className="heade">
                                    <span>{typeSubCategory}</span>
                                    <i><FontAwesomeIcon icon={faChevronRight}/></i>
                                </div>
                                {icon6 &&
                                    <div className="cat category_container">
                                        {product_statu?.data?.data?.product_status_data.map((e, i) => 
                                            <p onClick={() => (setTypeSubCategory(e), setIcon6(!icon6))}>{e}</p>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                            
                        <div className="Add_img mt-4">
                        <p className="mb-0">{t('color_images')}</p>

                            <div className="add" {...getRootPropsMultipleColor()}>
                            <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "100%", overflow: "hidden" }}>
                                <input {...getInputPropsMultipleColor()} />
                                {!multipleImagesColor.length && (
                                    <div className="upload-placeholder">
                                        <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                        <p>{t('addProduct.dragAndDropMultiple')}</p>
                                    </div>
                                )}
                                <div className={`image-preview ${multipleImagesColor.length === 1 ? 'single' : 'multiple'} row`}>
                                    {multipleImagesColor.map((file, index) => (
                                        <img 
                                            key={index} 
                                            src={file instanceof File ? file.preview : file.image} // عرض الصورة من الـ backend أو المعاينة المحلية
                                            alt={index} 
                                            className="imgs col-4 mb-3" 
                                        />
                                    ))}
                                </div>
                            </PerfectScrollbar>
                            </div>
                        </div>

                        <div className="box mt-4" onClick={() => setIcon(!icon)}>
                <label htmlFor='productType'>{t('product_sizes')}</label>
                <div className="heade">
                    <span>{t('choose_sizes')}</span>
                    <i><FontAwesomeIcon icon={faChevronRight} /></i>
                </div>
            </div>

            {icon && (
                <div className="size-selection">
                    <PerfectScrollbar style={{ maxWidth: "100%", maxHeight: "200px", overflow: "hidden" }}>
                        
                        {/* المقاسات الجاهزة */}
                        {allSizes.map((size, index) => (
                            <div key={index} className="selection">
                                <input 
                                    type="checkbox" 
                                    id={`size-${size}`} 
                                    value={size} 
                                    onChange={handleSizeChange}
                                />
                                <label htmlFor={`size-${size}`}>{size}</label>
                            </div>
                        ))}

                        {/* المقاسات المخصصة */}
                        {customSizes.map((input, index) => (
                            <div key={index} className="input-group">
                                <input
                                    type="text"
                                    placeholder={t('enter_custom_size')}
                                    value={input.size}
                                    onChange={(e) => handleCustomSizeChange(index, e.target.value)}
                                />
                                <p 
                                    className="addSize" 
                                    onClick={() => addCustomSizeToList(input.size)}
                                >
                                    {t('add')}
                                </p>
                            </div>
                        ))}

                        {/* زرار لإضافة حقل جديد */}
                        <p className="addSizeField" onClick={addSizeField}>
                            {t('add_new_field')}
                        </p>
                    </PerfectScrollbar>
                </div>
            )}



<div className="size-selection-text">
        <p>
          {t('selected_sizes')} 
          {sizes.map((item, index) => (
            <span key={index}>{item}, </span>
          ))}
        </p>
      </div>

      {/* الخامة */}
      <div className="div2 col-3">
        <label htmlFor='material' className="mb-3">{t('material')}</label>
        <input
          type="text"
          id="material"
          onChange={(e) => setMaterial(e.target.value)}
          value={material}
          placeholder={t('material_placeholder')}
        />
      </div>

      {/* تركيبة الخامة */}
      <div className="div2 col-3">
        <input
          type="text"
          id="materialComposition"
          onChange={(e) => setMaterialComposition(e.target.value)}
          value={materialComposition}
          placeholder={t('material_composition_placeholder')}
        />
      </div>

      {/* الضمان والعناية */}
      <div className="div2 col-3">
        <label htmlFor='warrantyCare' className="mb-3">{t('warranty_care')}</label>
        <input
          type="text"
          id="warrantyCare"
          onChange={(e) => setWarrantyCare(e.target.value)}
          value={warrantyCare}
          placeholder={t('warranty_care_placeholder')}
        />
      </div>

      {/* المواصفات العامة */}
      <div className="div2 col-3">
        <label htmlFor='generalSpecifications' className="mb-3">{t('general_specifications')}</label>
        <input
          type="text"
          id="generalSpecifications"
          onChange={(e) => setGeneralSpecifications(e.target.value)}
          value={generalSpecifications}
          placeholder={t('general_specifications_placeholder')}
        />
      </div>

      {/* راحة الطبيعة */}
      <div className="div2 col-3">
        <input
          type="text"
          id="natureComfort"
          onChange={(e) => setNatureComfort(e.target.value)}
          value={natureComfort}
          placeholder={t('natures_comfort_placeholder')}
        />
      </div>

      {/* المناسبة */}
      <div className="div2 col-3">
        <input
          type="text"
          id="occasion"
          onChange={(e) => setOccasion(e.target.value)}
          value={occasion}
          placeholder={t('occasion_placeholder')}
        />
      </div>

      {/* الموديل */}
      <div className="div2 col-3">
        <input
          type="text"
          id="model"
          onChange={(e) => setModel(e.target.value)}
          value={model}
          placeholder={t('model_placeholder')}
        />
      </div>

                </div >
                {productD && showPutton ? 
                <>
                    <button className='col-4 mt-5 mb-3' onClick={editProduct} type="submit">{t('edit')}</button>
                    <button className='col-4 mt-5 mb-3' style={{background:'red'}} onClick={resetInputs} type="button">{t('censle')}</button>
                    </>
:
<button className='col-4 mt-5 mb-3' onClick={uploadTask} type="submit">{t('addProduct.create')}</button>

                }
                </form>
            </div>

            </div>
            <div className={`popup ${showPopup ? 'show' : ''}`}>{t('addProduct.errorFields')}</div>
            {showPopup_massge && (
                <div className="popup-message">
                    <div className="popup-conten">
                    {edit_products?.data?.message&&
                    <FontAwesomeIcon icon={faCheck} className='mx-1' />
                    }
                    <span>{edit_products?.data?.message}</span>
                    <span>{post_products?.data?.message}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AddProduct;

