import './Categoires.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEdit, faPlus, faTags, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { category, delete_category, show_category } from "./Redux/API";
import { useEffect } from 'react';
import Loader from './Loader';


function Categoires() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gotoedit_category = (id) => {
    localStorage.setItem("category_id", id);
    dispatch(show_category(id));
    navigate('/EditCategory');
    window.scrollTo(0, 0);
  };


  const loadingDelete_category = useSelector(state => state.Data.loadingDelete_category);
  const loadingCategory = useSelector(state => state.Data.loadingCategory);
  const categoryS = useSelector(state => state.Data.category);

  useEffect(() => {
    dispatch(category())
  }, [dispatch, loadingDelete_category])

  const gotoAddCategoire = () => {
    navigate('/AddCategoire');
    dispatch(show_category(''));
    localStorage.removeItem("category_id"); // حذف البيانات من localStorage

  }
  
  const goBack = () => {
    navigate(-1);
  }

  const listOrders = t('categoires.columns', { returnObjects: true });
  const [showCategory, setShowCategory] = useState(null);
  const [showsupCategory, setShowsupCategory] = useState(null);
  const toggleShowCategory = (id) => setShowCategory(showCategory === id ? null : id);
  const toggleShowSupCategory = (id) => setShowsupCategory(showsupCategory === id ? null : id);


  const handleDeleteCategoires = (id) => {
    dispatch(delete_category(id));
  };
console.log(categoryS)

  return (
    <div className="Categoires">
    <Header />
    <div className='container-fluid'>
      {loadingDelete_category && <Loader />}
      {loadingCategory && <Loader />}

      <div className='container mt-2'>
        <div className='row'>
          <div className='sarsh'>
            <div className='AddCat' onClick={gotoAddCategoire}>
              <span><FontAwesomeIcon icon={faPlus} /></span>
              <span>{t('categoires.add_categoire')}</span>
            </div>
          </div>
        </div>
      </div>

      <div className='container'>
          <div className='row'>
            <div className='sarsh'>
              <div className='AddCat mt-2' onClick={gotoAddCategoire}>
                <span><FontAwesomeIcon icon={faPlus} /></span>
                <span>{t('categoires.add_categoire')}</span> {/* ترجمة النص */}
              </div>
            </div>
          </div>
        </div>

      <div className='container'>
        <div className='Orders'>
          <div className='Orders_border row justify-content-around'>
            {listOrders.map((e, index) => (
              <p className='col-2 text-center' key={index}>{e}</p>
            ))}
          </div>
          <>
          {categoryS?.data?.data.map((item) => (
              <div key={item.id}>
                  <div
                      className='Orders_border hover row justify-content-around'
                      onClick={(() => toggleShowCategory(item.id))}
                      >
                      <p className='col-2 text-center'>{item.id}</p>
                      <p className='col-2 text-center'><img src={item.image} alt={item.id}/></p>
                      <p className='col-2 text-center'>{item.name}</p>
                      <p className='col-2 text-center'>
                        <span className='span_icon' onClick={() => gotoedit_category(item.id)}><FontAwesomeIcon icon={faEdit} /></span>
                        <span 
                            className='span_icon2' 
                            onClick={() => handleDeleteCategoires(item.id)}  // تمرير الـ id للباك إند
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>                   
                      </p>
                  </div>

                  <div className={`ProductsWrapper ${showCategory === item.id ? 'active' : ''}`}>
                    
                    {item?.subcategories.map((item, index) => (
                      <div
                      onClick={(() => toggleShowSupCategory(item.id))}

                      className='Orders_border hover row justify-content-around '
                      
                      key={index}>
                      <p className='col-2 text-center'>{item.id}</p>
                      <p className='col-2 text-center'><img src={item.image} alt={item.id}/></p>
                      <p className='col-2 text-center'>{item.name}</p>
                      <p className='col-2 text-center'>
                        <span className='span_icon' onClick={() => gotoedit_category(item.id)}><FontAwesomeIcon icon={faEdit} /></span>
                        <span 
                            className='span_icon2' 
                            onClick={() => handleDeleteCategoires(item.id)}  // تمرير الـ id للباك إند
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>                   
                      </p>
                      </div>
                    ))}
                  </div>
              </div>
            ))}
          </>

        </div>
      </div>
    </div>
  </div>
  );
}

export default Categoires;

